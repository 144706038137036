export const convertPriceToMoney = (cents) => {
  if (!cents) return `R$ 0`

  const price = cents / 100;
  return price.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export const capitalizeFirstLetter = (value) => {
  if (!value) return

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const phoneMask = (value) => {
  if (!value) return ""

  const formattedValue = value
    .replace(/\D/g,'')
    .replace(/(\d{2})(\d)/,"($1) $2")
    .replace(/(\d)(\d{4})$/,"$1-$2")
  return formattedValue
}

export const unmaskPhone = (maskedValue) => {
  if (!maskedValue) return "";

  const unformattedValue = maskedValue.replace(/\D/g, '');
  return unformattedValue;
}