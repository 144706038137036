import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";

async function createSimulationMutation({
  age,
  dependents,
  products,
  selectedProductId,
  voucher,
  utm_source,
  leadId,
}) {
  const response = await axios.post(
    `${API}/simulations`,
    {
      age,
      products,
      selectedProductId,
      dependents,
      voucher,
      utm_source,
      leadId,
    },
  );
  return response.data;
}

export const simulationsMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => createSimulationMutation(e),
  );

  return {
    isSuccessSimulationUseCase: isSuccess,
    dataSimulationUseCase: data,
    isLoadingSimulationUseCase: isLoading,
    errorSimulationUseCase: error,
    mutateCreateSimulation: mutate,
  }
}
