import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

const STATES_MAP = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraná",
  "Paraíba",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins",
]

export const allFiltersModel = ({
  onClose,
  onChangeStateMutation,
  onChangeHospitals,
  nextBaseUrl,
}) => {
  const queryParams = new URLSearchParams(location.search);
  const [selectedHospital, setSelectedHospital] = useState([]);
  const [accommodation, setAccommodation] = useState(() => {
    const currentAccommodations = queryParams.get("accommodation")
    if (!currentAccommodations) {
      return {
        private: true,
        shared: true,
      }
    }
    return {
      private: currentAccommodations.includes("private"),
      shared: currentAccommodations.includes("shared"),
    }
  });
  const [copayments, setCopayments] = useState(() => {
    const currentCopayments = queryParams.get("copayment")

    if (!currentCopayments) {
      return {
        without: true,
        with: true,
        with_partial: true,
      }
    }
    const copaymentsSplit = currentCopayments.split(",")
    return {
      without: copaymentsSplit.some(value => value === "without"),
      with: copaymentsSplit.some(value => value === "with"),
      with_partial: copaymentsSplit.some(value => value === "with_partial"),
    }
  });

  const handleAccommodationChange = (type) => {
    setAccommodation((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleCopaymentChange = (type) => {
    setCopayments((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const navigate = useNavigate();
  const { age } = useParams();
  const [currentPrice, setCurrentPrice] = useState(() => {
    const currentMaxPrice = queryParams.get("maxPrice")
    if (currentMaxPrice) {
      return Number(currentMaxPrice);
    }

    return 0;
  })

  const [selectedState, setSelectedState] = useState(() => {
    
    const currentState = queryParams.get("state")
    if (currentState) {
      return currentState
    }

    return "Rio de Janeiro"
  });

  const onSelectHospital = (hospitals) => {
    setSelectedHospital(hospitals);
    onChangeHospitals(hospitals);
  }
  
  const handleChange = (e) => {
    setSelectedState(e.target.value)
    onChangeStateMutation(e.target.value)
    setParamsUrl(e.target.value);
  }

  const onClickFilterSubmit = () => {
    setParamsUrl();
    onClose();
  }
  const setParamsUrl = () => {
    queryParams.delete("state")
    queryParams.delete("maxPrice")
    queryParams.delete("hospitals")
    queryParams.delete("accommodation")
    queryParams.delete("copayment")
    queryParams.append("state", selectedState);

    if (currentPrice) {
      queryParams.append("maxPrice", currentPrice);
    }

    if (selectedHospital.length) {
      const hospitals = selectedHospital.join(",");
      queryParams.append("hospitals", hospitals);
    }

    const hasAccommodation = Object.values(accommodation).some(value => value === true)
    if (hasAccommodation) {
      const accommodationsSelected = Object.keys(accommodation).filter(key => accommodation[key] === true).join(",");
      queryParams.append("accommodation", accommodationsSelected);
    }

    const hasCopayments = Object.values(copayments).some(value => value === true);
    if (hasCopayments) {
      const copaymentsSelected = Object.keys(copayments).filter(key => copayments[key] === true).join(",");
      queryParams.append("copayment", copaymentsSelected);
    }

    const url = `/${age}/${nextBaseUrl}?${queryParams.toString()}`;
    navigate(url)
  }

  const onChangePrice = (value) => {
    setCurrentPrice(value)
  }

  const onClickOtherStates = () => {
    const message = `Olá, gostaria de informação sobre outros estados`;
    const url = `https://api.whatsapp.com/send?phone=5521971779781&text=${encodeURI(message)}&app_absent=0`;
    window.open(url);
  }

  return {
    states: STATES_MAP,
    selectedState,
    onClickFilterSubmit,
    onChangePrice,
    currentPrice,
    onSelectHospital,
    onClickOtherStates,
    handleChange,
    handleAccommodationChange,
    accommodation,
    copayments,
    handleCopaymentChange,
  }
}