import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { productsQueryUseCase } from "../useCases/products/mutations/productsMutation";
import { getAccommodationMinified, getAdherence, getCopayment } from "../../../utils/i18n-product";
import { isMobile } from "react-device-detect";
import { trackAction, trackPage } from "../../../utils/segment";
import { vouchersQuery } from "../../../components/organisms/VoucherFilter/useCases/queries/getVouchersQuery";
import { setFromLS } from "../../../utils/localStorage";
import { accreditNetworksQueryOnDemand } from "../useCases/accreditNetworks/queries/accreditNetworksQueryOnDemand";
import { isBelowMaxAge } from "../../../utils/parser";

export const productsModel = () => {
  const [selectedProducts, setSelectedProducts] = useState([])
  const [openAllFilters, setOpenAllFilters] = useState(false);
  const [builtProducts, setBuiltProducts] = useState([]);
  const [openLifeManagementFilter, setOpenLifeManagementFilter] = useState(false);
  const [openVoucherFilter, setOpenVoucherFilter] = useState(false);
  const [availableHospitals, setAvailableHospitals] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { age } = useParams();
  const [viewInformation, setViewInforamtion] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get('state');
  const dependentsAges = queryParams.get('dependentsAges');
  const maxPrice = queryParams.get('maxPrice');
  const voucher = queryParams.get('voucher');
  const hospitals = queryParams.get('hospitals');
  const providerIdsQueryParam = queryParams.get('providerId');
  const copayment = queryParams.get("copayment");
  const accommodation = queryParams.get("accommodation");
  const [currentState, setCurrentState] = useState(state)

  trackPage("plg-products")

  const {
    dataVouchersUseCase,
    isSuccessVouchersUseCase,
    isLoadingVouchersUseCase,
  } = vouchersQuery({ code: voucher });

  const {
    accreditNetworksData,
    accreditNetworksIsSuccess,
    refetchAccreditNetwork,
    accreditNetworksIsLoading,
  } = accreditNetworksQueryOnDemand()

  const {
    isSuccessProductQueryUseCase,
    dataProductQueryUseCase,
    isLoadingProductQueryUseCase,
  } = productsQueryUseCase({
    age,
    dependents: dependentsAges ? dependentsAges.split(",") : [],
    state,
    providerIds: providerIdsQueryParam.split(","),
    maxPrice,
    copayment,
    accommodation,
  });

  const checkedViewInformation = (productId) => {
    if (isMobile) {
      return viewInformation.some(product => product === productId);
    }
    return true;
  }
  const handleViewInformation = (productId) => {
    if(viewInformation.some(product => product === productId)){
      setViewInforamtion(viewInformation.filter(product => product !== productId));
    } else{
      setViewInforamtion([...viewInformation, productId]);
    }
  }

  const onSelectProduct = (productId) => {
    const currentProduct = builtProducts.find((product) => product.id === productId);
    const alreadyClickedProduct = selectedProducts.some(product => product.id === productId)

    if (alreadyClickedProduct) {
      setSelectedProducts(selectedProducts.filter(product => product.id !== productId))
    } else {
      setSelectedProducts([...selectedProducts, currentProduct])
    }
  }

  const isCheckedProduct = (productId) => {
    return selectedProducts.some(product => product.id === productId);
  }
  
  const redirectToCompare = () => {
    trackAction('plg-products-continue-button', {
      selectedProductsQuantity: selectedProducts.length,
    })
    queryParams.delete("productId")
    queryParams.delete("productGroupId")

    const productGroupIds = selectedProducts.map(product => product.productGroupId).join(",")
    queryParams.append("productGroupId", productGroupIds)

    const productIds = selectedProducts.map(product => product.id).join(",")
    queryParams.append("productId", productIds);

    if(!state) {
      queryParams.append("state", state);
    }

    const url = `/${age}/providers/products/compare?${queryParams.toString()}`;
    navigate(url)
  }

  const getAvailableVoucher = (voucher, providerId) => {
    if (!voucher) return

    return vouchers.cashback.find(voucher =>
      voucher.providerId === providerId,
    )
  }

  const getMaxAgeWithin = () => {
    const dependents = dependentsAges ? dependentsAges.split(",").map(d => Number(d)) : []

    const agesArray = [Number(age), ...dependents]
    
    return Math.max(...agesArray)
  }

  const buildProductsWithVoucher = (
    currentProducts,
    vouchers,
    accreditNetworks,
  ) => {
    const maxAgeSelected = getMaxAgeWithin()

    const productBuilt = currentProducts.reduce((acc, product) => {
      const voucherFound = getAvailableVoucher(vouchers[0], product.provider.id)

      if (!isBelowMaxAge(product.provider.maxAgeAcceptance, maxAgeSelected)) {
        return acc;
      }

      return [
        ...acc,
        {
          id: product.id,
          accommodation: getAccommodationMinified(product.accommodation),
          adherence: getAdherence(product.adherence),
          copayment: getCopayment(product.copayment),
          copaymentDescription: product.copaymentDescription,
          fromAge: product.fromAge,
          name: product.name,
          price: (product.price / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          provider: {
            id: product.provider.id,
            name: product.provider.name,
          },
          productGroupId: product.productGroupId,
          pricesByAge: product.pricesByAge,
          toAge: product.age,
          hospitals: product.hospitals,
          laboratories: product.laboratories,
          state: product.state,
          reimbursement: product.reimbursement ?
            (product.reimbursement / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 })
            : 0,
          ...(voucherFound && {
            cashback: voucherFound ? voucherFound.percentage : undefined,
            cashbackDuration: voucherFound ? voucherFound.months : undefined,
            cashbackPrice: voucherFound ?
              product.price * (voucherFound.percentage / 100)
              : 0,
          }),
        },
      ]
    }, [])

    if (!accreditNetworks?.length) return productBuilt

    if (hospitals) {
      return productBuilt.filter(p => {
        const accreditFound = accreditNetworks.find(accredit =>
          accredit.productGroupId === p.productGroupId,
        )

        if (!accreditFound) return false

        const hospitalsBuild = hospitals.split(",");
        return accreditFound.hospitals.some(hospital =>
          hospitalsBuild.includes(hospital.name),
        )
      })
    }

    return productBuilt.filter(p => {
      const productFound = accreditNetworks.some(accredit =>
        accredit.productGroupId === p.productGroupId,
      )
      return productFound
    })
  }

  const forwardTextButton = () => {
    if (!selectedProducts?.length) {
      return `Comparar`
    }
    if (selectedProducts.length === 1) {
      return `Comparar (1 selecionado)`
    }
    return `Comparar (${selectedProducts.length} selecionados)`
  }

  const onClickAllFiltersOpen = () => {
    refetchAccreditNetwork({ state })
    setOpenAllFilters(true);
  }

  const onClickAllFiltersClose = () => {
    setOpenAllFilters(false);
  }

  const onClickLifeManagementFilterOpen = () => {
    setOpenLifeManagementFilter(true);
  }

  const onClickLifeManagementFilterClose = () => {
    setOpenLifeManagementFilter(false);
  }

  const onClickVoucherFilterOpen = () => {
    setOpenVoucherFilter(true);
  }

  const onClickVoucherFilterClose = () => {
    setOpenVoucherFilter(false);
  }

  const getAccommodationTypes = (accommodation) => {
    if (!accommodation) {
      return ["shared", "private"]
    }
    return accommodation.split(",")
  }

  const onChangeStateMutation = (state) => {
    refetchAccreditNetwork({ state })
    setCurrentState(state)
  }

  const getCopaymentTypes= (copayment) => {
    if (!copayment) {
      return ["with", "with_partial", "without"]
    }
    return copayment.split(",")
  }

  const onChangeHospitals = (hospitalsSelected) => {
    if (!hospitalsSelected.length) return

    const accreditsWithHospitalsChosen = accreditNetworksData.filter(accredit => {
      const hasHospital = accredit.hospitals.find(hospital =>
        hospitalsSelected.includes(hospital.name),
      )
      return hasHospital
    }).map(accredit => accredit.productGroupId)

    const filterByHospitals = dataProductQueryUseCase.filter(product =>
      accreditsWithHospitalsChosen.includes(product.productGroupId),
    )

    setBuiltProducts(
      buildProductsWithVoucher(
        filterByHospitals,
        dataVouchersUseCase,
      ),
    )    
  }

  const onClearHospitals = () => {
    setBuiltProducts(
      buildProductsWithVoucher(
        dataProductQueryUseCase,
        dataVouchersUseCase,
      ),
    )  
  }

  useEffect(() => {
    if (accreditNetworksIsSuccess) {
      const uniqueNames = new Set(
        accreditNetworksData.flatMap(accredit => accredit.hospitals.map(hospital => hospital.name)),
      );
      const hospitalNames = Array.from(uniqueNames)
      setAvailableHospitals(hospitalNames);
    }
  }, [accreditNetworksIsSuccess])

  useEffect(() => {
    refetchAccreditNetwork({ state })
    const utm_source = queryParams.get('utm_source');
    if (utm_source) {
      setFromLS("utm_source", utm_source)
    }
    if (hospitals) {
      refetchAccreditNetwork({
        state,
        providerIds: providerIdsQueryParam,
        hospitalNames: hospitals,
      })
    }
  }, [])

  useEffect(() => {
    if (isSuccessProductQueryUseCase && isSuccessVouchersUseCase && accreditNetworksIsSuccess) {
      const productsWithVoucher = buildProductsWithVoucher(
        dataProductQueryUseCase,
        dataVouchersUseCase,
        accreditNetworksData,
      )
      setBuiltProducts(productsWithVoucher)

      const productExpanded = productsWithVoucher.length ? [productsWithVoucher[0].id] : []
      setViewInforamtion(productExpanded)
    }
  }, [isSuccessProductQueryUseCase, isSuccessVouchersUseCase, accreditNetworksIsSuccess])

  return {
    isCheckedProduct,
    onSelectProduct,
    redirectToCompare,
    forwardTextButton,
    isDisabledToCompareButton: !selectedProducts?.length,
    handleViewInformation,
    checkedViewInformation,
    viewInformation,
    providersProducts: builtProducts,
    isLoading: isLoadingProductQueryUseCase || accreditNetworksIsLoading || isLoadingVouchersUseCase,
    onClickAllFiltersOpen,
    onClickLifeManagementFilterOpen,
    onClickVoucherFilterOpen,
    stateSelected: currentState,
    lifeQuantity: dependentsAges ? dependentsAges.split(",").length + 1 : 1,
    copayment: getCopaymentTypes(copayment),
    accommodation: getAccommodationTypes(accommodation),
    openAllFilters,
    openLifeManagementFilter,
    openVoucherFilter,
    onClickAllFiltersClose,
    availableHospitals,
    onChangeStateMutation,
    onChangeHospitals,
    onClearHospitals,
    accreditNetworksIsLoading,
    onClickLifeManagementFilterClose,
    onClickVoucherFilterClose,
  }
}
