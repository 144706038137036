import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../utils/http";

async function getHospitalsByProviderIdAndproductGroupId({ providerId, productGroupId }) {
  const response = await axios.get(
    `${API}/hospitals?providerId=${providerId}&productGroupId=${productGroupId}`,
  );
  return response.data;
}

export const hospitalsQueryUseCase = ({ providerId, productGroupId }) => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useQuery(['HospitalsUseCaseQuery', providerId, productGroupId],
  () => getHospitalsByProviderIdAndproductGroupId({ providerId, productGroupId }));

  return {
    hospitalsIsSuccess: isSuccess,
    hospitalsData: data,
    hospitalsIsLoading: isLoading,
    hospitalsError: error,
    refetchHospitals: mutate,
  }
}