import Flex from '../../../components/atoms/Flex/Flex';
import Typography from '../../../components/atoms/Typography/Typography';


const FilterButton = ({
  onClick,
  text,
  icon,
}) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-around"}
      style={{
        borderRadius: 4,
        border: "1px solid #5900CC",
        padding: "0px 10px",
        cursor: "pointer",
        padding: "8px 0px",
      }}
      onClick={onClick}
    >
      <Flex
        alignItems={"center"}
        gapSize={"sm"}
      >
        {icon}
        <Typography
          variant="subheading2"
          fontWeight={500}
          color={"primary"}
        >
          {text}
        </Typography>
      </Flex>
    </Flex>
  )
}

export default FilterButton