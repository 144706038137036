import { isMobile } from "react-device-detect";
import Flex from "../../components/atoms/Flex/Flex";
import Typography from "../../components/atoms/Typography/Typography";
import Input from "../../components/molecules/Input";
import { landingPageModel } from "./viewModel/landingPageModel";
import ButtonFixed from "../../components/molecules/ButtonFixed";
import { phoneMask } from "../../utils/masks";

const LandingPageRoute = () => {
  const {
    handleFetchProductsByAge,
    form,
    onChangeInput,
    hasErrors,
    errors,
  } = landingPageModel();
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      style={{
        margin: "90px 30px 0px 30px",
        height: "100%",
      }}
    >
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        style={{
          gap: "12px",
          ...(!isMobile && {
            width: "70%",
            alignItems: "center",
          }),
        }}
      >
        <Typography
          variant={"h5"}
          fontWeight={400}
          color={"info"}
          style={{
            textAlign: "center",
          }}
        >
          Qual a sua idade?
        </Typography>
        <Typography
          variant={"body1"}
          fontWeight={400}
          color={"secondary"}
          style={{
            textAlign: "center",
          }}
        >
          Apenas com a sua idade você vai ter acesso a todas as informações.
        </Typography>
        <Input
          placeholder={"idade"}
          type={"tel"}
          defaultValue={form.age}
          onChange={(e) => onChangeInput('age', e.target.value)}
          hasError={!!errors.age}
        />
        {Boolean(errors.age) && (
          <Typography
            variant={"body1"}
            fontWeight={400}
            color={"error"}
          >
            {errors.age}
          </Typography>
        )}
        <Input
          placeholder={"telefone (opcional)"}
          type={"tel"}
          defaultValue={phoneMask(form.cellphone)}
          onChange={(e) => onChangeInput('cellphone', e.target.value)}
          hasError={!!errors.cellphone}
        />
        <Input
          placeholder={"E-mail (opcional)"}
          type={"text"}
          defaultValue={form.email}
          onChange={(e) => onChangeInput('email', e.target.value)}
          hasError={!!errors.email}
        />
      </Flex>
      <ButtonFixed
        onClick={handleFetchProductsByAge}
        text={"Vamos lá!"}
        disabled={hasErrors}
      />
    </Flex>
  )
}

export default LandingPageRoute;