import Flex from "../../../atoms/Flex/Flex"
import Typography from "../../../atoms/Typography/Typography";

const StateFilter = ({
  states,
  handleChange,
  selectedState,
}) => {
  return (
    <Flex flexDirection={"column"}>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Flex
          flexDirection={"row"}
        >
          <Typography
            variant={"subheading1"}
            fontWeight={700}
            color={"terceary"}
          >
            Estado
          </Typography>
        </Flex>
      </Flex>
      <Flex
        style={{
          flexWrap: "wrap",
          marginTop: 8,
          gap: 8,
        }}
      >
         <select
            style={{
              width:"100%",
              borderRadius: 4,
              padding: 10,
              border: "1px solid #D5D9F2",
            }}
            value={selectedState} 
            onChange={handleChange}
         >
          {states.map(state => (
            <option 
              key={state}
              value={state}
            >
              {state}
            </option>
          ))}
        </select>
      </Flex>
    </Flex>
  )
}

export default StateFilter;