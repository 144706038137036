import axios from "axios";
import { useMutation, useQuery } from 'react-query';
import { API } from "../../../../../utils/http";

async function getVouchersMutation({ code }) {
  const response = await axios.get(
    `${API}/vouchers?code=${code}`,
  );
  return response.data;
}

export const vouchersMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => getVouchersMutation(e),
  );

  return {
    isSuccessVouchersUseCase: isSuccess,
    dataVouchersUseCase: data,
    isLoadingVouchersUseCase: isLoading,
    errorVouchersUseCase: error,
    getVouchersUseCase: mutate,
  }
}

export const vouchersQuery = ({ code }) => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
  } = useQuery(
    ["vouchersQueryV2", code],
    () => getVouchersMutation({ code }),
  );

  return {
    isSuccessVouchersUseCase: isSuccess,
    dataVouchersUseCase: data,
    isLoadingVouchersUseCase: isLoading,
    errorVouchersUseCase: error,
  }
}
