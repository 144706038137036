import Divider from "../../../components/atoms/Divider/Divider";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import { FaAngleRight } from "react-icons/fa";

const HospitalSection = ({ hospitals, onClickHospitalMenu }) => {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gapSize="10px"
        onClick={onClickHospitalMenu}
        style={{ cursor: "pointer" }}
      >
        <Flex
          justifyContent="space-between"
        >
          <Typography
            variant="subheading2"
            fontWeight={500}
            color="terceary"
          >
            Hospitais ({hospitals?.length})
          </Typography>
          <FaAngleRight
            style={{
              marginRight: "5px",
              color: "midnightblue",
            }}
          />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="baseline"
          alignItems="baseline"
          style={{ textAlign: "left" }}
        >
          {hospitals?.slice(0, 2).map(hospital => (
            <Typography
              key={hospital.name}
              variant="subheading1"
              fontWeight={600}
              color="terceary"
            >
              {hospital.name}
            </Typography>
          ))}
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};

export default HospitalSection;
