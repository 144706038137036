import Flex from "../../components/atoms/Flex/Flex";
import Typography from "../../components/atoms/Typography/Typography";
import { laboratoriesModel } from "./viewModel/laboratoriesModel"
import { nanoid } from "nanoid";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import Divider from "../../components/atoms/Divider/Divider";
import Drawer from "../../components/molecules/Drawer";
import { ReactComponent as DrawerRectanbleIcon } from '../../assets/drawer-rectangle.svg';

const LaboratoriesRoute = ({
  laboratories,
  visible,
  onClose,
}) => {
  const {
    labs,
    shouldDisplayUnits,
    setDisplayUnits,
    laboratoryInputValue,
    setLaboratoryInputValue,
  } = laboratoriesModel({
    laboratories,
  });

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      size={"lg"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        style={{
          padding: "20px",
          height: "100%",
          width: "100%",
        }}
      >
        <DrawerRectanbleIcon style={{ marginTop: 36 }}/>
        <Typography
          variant={"h5"}
          fontWeight={400}
          color={"terceary"}
          style={{
            marginTop: 32,
            marginBottom: 16,
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          Laboratórios
        </Typography>
        <input
          placeholder={"Buscar por laboratório"}
          type={"text"}
          defaultValue={laboratoryInputValue}
          onChange={(e) => setLaboratoryInputValue(e.target.value)}
          style={{
            marginTop: 12,
            marginBottom: 24,
            width: "85%",
            padding: 12,
            borderRadius: 4,
            border: "1px solid rgba(213, 217, 242, 1)",
          }}
        />
        <Flex
          flexDirection={"column"}
          gapSize={"md"}
          style={{
            overflowY: "scroll",
            maxHeight: "70vh",
            width: "100%",
          }}
        >
          {Object.entries(labs).map(([laboratoryName, laboratoryUnits]) => {
            return (
            <Flex
              flexDirection={"column"}
              key={nanoid()}
              style={{
                padding: "15px 10px",
                width: "90%",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                borderRadius: 8,
                alignSelf: "center",
              }}
            >
              <Flex
                justifyContent={"space-between"}
                style={{
                  ...(laboratoryUnits.length && {
                    cursor: "pointer",
                  }),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDisplayUnits(laboratoryName);
                }}
              >
                <Typography
                  variant="subheading2"
                  fontWeight={700}
                  color={"primary"}
                >
                  {laboratoryName}
                </Typography>
                {laboratoryUnits.length ?
                  shouldDisplayUnits[laboratoryName] ?
                    <FaChevronDown color="rgba(89, 0, 204, 1)"/> :
                    <FaChevronRight color="rgba(89, 0, 204, 1)"/> :
                    <></>
                }
              </Flex>
              {shouldDisplayUnits[laboratoryName] && (
                <Flex flexDirection={"column"}>
                  {laboratoryUnits.length ? (
                    <Flex flexDirection={"column"}>
                      <Typography
                        variant="subheading2"
                        fontWeight={700}
                        style={{ marginTop: 20 }}
                      >
                        Unidades
                      </Typography>
                    </Flex>
                    ): <></>
                  }
                  {laboratoryUnits.length ? laboratoryUnits.map(unit => (
                    <Flex
                      key={nanoid()}
                      flexDirection={"column"}
                    >
                      <Typography
                        variant="subheading2"
                        fontWeight={500}
                        style={{ padding: 12 }}
                      >
                        {unit}
                      </Typography>
                      <Divider/>
                    </Flex>
                  )) : <></>}
                </Flex>
              )}
            </Flex>
          )})}
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default LaboratoriesRoute;