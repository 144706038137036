import { isMobile } from 'react-device-detect';
import Flex from '../../../components/atoms/Flex/Flex';
import Typography from '../../../components/atoms/Typography/Typography';
import { IoArrowForward } from "react-icons/io5";
import { ReactComponent as DucaPositiveIcon } from '../../../assets/duca_positive.svg';


const CashbackSection = ({
  onClick,
}) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      style={{
        width: isMobile ? "80%" : 290,
        marginTop: 12,
        borderRadius: 8,
        border: "1px solid #5900CC",
        padding: "10px 20px",
        alignSelf: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Flex alignItems={"center"} gapSize>
        <DucaPositiveIcon width={12}/>
        <Typography
          variant="subheading2"
          fontWeight={500}
          color={"primary"}
        >
          Insira seu cashback aqui
        </Typography>
      </Flex>
      {isMobile && <IoArrowForward size={16} color={"#5900CC"}/>}
    </Flex>
  )
}

export default CashbackSection