import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../../utils/http";

async function getAccreditNetworkByGroupProductId({ productGroupIds }) {
  const response = await axios.get(
    `${API}/accredit-networks?productGroupId=${productGroupIds}`,
  );
  return response.data;
}

export const accreditNetworksQuery = ({
  productGroupIds,
}) => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
  } = useQuery(
    ["accreditNetworksQuery", productGroupIds],
    () => getAccreditNetworkByGroupProductId({ productGroupIds }),
  );

  return {
    accreditNetworksIsSuccess: isSuccess,
    accreditNetworksData: data,
    accreditNetworksIsLoading: isLoading,
    accreditNetworksError: error,
  }
}