import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './AppRoutes';
import { FiltersProvider } from './contexts/FilterContext/FilterContext';
import * as Sentry from "@sentry/react";
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from './contexts/FeatureToggle/GrowthBook';
import { useEffect } from 'react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

const queryClient = new QueryClient()

const App = () => {
  const featureToggle = growthbook();
  useEffect(() => {
    featureToggle.init({ streaming: true });
  }, []);
  return (
    <div style={{
      display: 'flex',
      height: '100%',
    }}>
      <FiltersProvider>
        <GrowthBookProvider growthbook={featureToggle}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes/>
          </QueryClientProvider>
        </GrowthBookProvider>
      </FiltersProvider>
    </div>
  )
}

export default App;
