import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";
import { buildQueryParams } from "../../../../../utils/utils";

async function getAccreditNetworkByProviderId({
  state,
  hospitalNames,
  providerIds,
}) {
  const params = {
    state,
    hospitalNames,
    providerIds,
  };
  const queryString = buildQueryParams(params);

  const response = await axios.get(`${API}/accredit-networks?${queryString}`);
  return response.data;
}

export const accreditNetworksQueryOnDemand = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation((e) => getAccreditNetworkByProviderId(e));

  return {
    accreditNetworksIsSuccess: isSuccess,
    accreditNetworksData: data,
    accreditNetworksIsLoading: isLoading,
    accreditNetworksError: error,
    refetchAccreditNetwork: mutate,
  }
}