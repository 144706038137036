import { GrowthBook } from "@growthbook/growthbook-react";
import { nanoid } from "nanoid";
import { segmentIdentify, trackAction } from "../../utils/segment";

export const growthbook = () => {
  const nanoUserId = nanoid()
  segmentIdentify(nanoUserId)
  const path = window.location.pathname;
  return new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.REACT_APP_GROWTH_BOOK_CLIENT_KEY,
    enableDevMode: true,
    attributes: {
      id: nanoUserId,
      path,
    },
    trackingCallback: (experiment, result) => {
      trackAction("Viewed Experiment", {
        condition: experiment.condition,
        name: experiment.name,
        experimentId: experiment.key,
        seed: experiment.seed,
        variationId: result.key,
        value: result.value,
      });
    },
  });
}
