import 'rodal/lib/rodal.css';
import Typography from '../../atoms/Typography/Typography';
import Button from '../../molecules/Button';
import { lifesManagementFiltersModel } from './viewModel/lifesManagementFiltersModel';
import Flex from '../../atoms/Flex/Flex';
import Input from '../../molecules/Input';
import { FaRegTrashAlt } from 'react-icons/fa';
import { LuPlusCircle } from "react-icons/lu";
import Drawer from "../../molecules/Drawer";
import { ReactComponent as DrawerRectanbleIcon } from '../../../assets/drawer-rectangle.svg';

const LifesManagementFilters = ({
  onClose,
  visible,
  nextBaseUrl,
}) => {
  const {
    onClickFilterSubmit,
    dependents,
    onAddDependent,
    onRemoveDependent,
    onEditDependent,
  } = lifesManagementFiltersModel({
    nextBaseUrl,
    onClose,
  })

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      size={"md"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
      >
        <DrawerRectanbleIcon style={{ marginTop: 36 }}/>
        <Typography
          variant={"h6"}
          fontWeight={400}
          color={"info"}
          style={{ alignSelf: "center", marginTop: 30 }}
        >
         Adicionar vidas
        </Typography>
      </Flex>
      <Flex
        flexDirection={"column"}
        gapSize={"lg"}
        style={{ padding: "1rem", overflowY: "auto" }}
      >
        <Typography
          variant={"subheading1"}
          fontWeight={400}
          color={"secondary"}
        >
          Aqui, adicione seus dependentes e sócios ao plano.
        </Typography>
        {dependents?.map((dependent, index) => (
          <Flex
            key={dependent.id}
            flexDirection={"column"}
            gapSize
          >
            <Typography
              variant={"subheading1"}
              fontWeight={500}
              color={"terceary"}
            >
              Vida {index + 1}
            </Typography>
            <Flex
              gapSize={"md"}
              alignItems={"center"}
              justifyContent={"space-around"}
              style={{width: "100%"}}
            >
              <Input
                placeholder={"idade"}
                type={"number"}
                defaultValue={dependent.age}
                onChange={(e) => onEditDependent(dependent.id, e.target.value)}
                style={{
                  width: "100%",
                }}
              />
              <FaRegTrashAlt
                color={"#eb5757"}
                onClick={() => onRemoveDependent(dependent.id)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
          </Flex>
        ))}
        <Flex
          gapSize
          style={{
            alignSelf: "center",
            backgroundColor: "#F4F1FC",
            padding: "10px 12px",
            borderRadius: 24,
          }}
        >
          <LuPlusCircle color={"#eb5757"}/>
          <Typography
            variant="subheading2"
            fontWeight={700}
            color={"primary"}
            style={{
              cursor: "pointer",
            }}
            onClick={() => onAddDependent(0)}
          >
            Adicionar uma nova vida
          </Typography>
        </Flex>
      </Flex>
      <Button
        text={"Atualizar simulação"}
        onClick={onClickFilterSubmit}
        width={"250px"}
        height={"20px"}
        disabled={false}
      />
    </Drawer>
  )
}

export default LifesManagementFilters;