import Divider from "../../../components/atoms/Divider/Divider";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const LifeQuantitySection = ({
  buildHolderText,
  dependentsAges,
  buildDependentsText,
}) => {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gapSize="10px"
      >
        <Typography
          variant="subheading2"
          fontWeight={500}
          color="terceary"
        >
          Quantidade de vidas
        </Typography>
        <Typography
          variant="subheading1"
          fontWeight={600}
          color="terceary"
        >
          {buildHolderText()}
        </Typography>
        {dependentsAges && (
          <Typography
            variant="subheading1"
            fontWeight={600}
            color="terceary"
          >
            {buildDependentsText()}
          </Typography>
        )}
      </Flex>
      <Divider />
    </>
  );
};

export default LifeQuantitySection;
