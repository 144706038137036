import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { segmentIdentifyLead, trackAction, trackPage } from "../../../utils/segment";
import { isBelowMaxAge, validateCPF, validateEmail } from "../../../utils/parser";
import { leadMutation } from "../useCases/mutations/leadsMutation";
import { setFromLS } from "../../../utils/localStorage";
import { unmaskPhone } from "../../../utils/masks";

export const landingPageModel = () => {
  const navigate = useNavigate();
  const [form, updateForm] = useState({});
  const [errors, setErrors] = useState({
    age: true,
    cellphone: false,
    email: false,
  });
  const params = useParams()

  const {
    dataLeadUseCase,
    isSuccessLeadUseCase,
    mutateCreateLead,
  } = leadMutation()
  const onChangeInput = (inputName, value) => {
    validateInput(inputName, value)
    updateForm({
      ...form,
      [inputName]: value,
    })
  }

  const validateInput = (inputName, value) => {
    if (!value.trim()) {
      if (inputName === 'age') {
        return
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: `${inputName} é obrigatório.`,
      }));
    } else {
      if (inputName === "cpf") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateCPF(value) ? null : `Digite um CPF válido`,
        }));
      } else if (inputName === "email"){
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateEmail(value) ? null : `Digite um email válido`,
        }));
      } else if (inputName === "age") {
        const MAX_AGE = 75;
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: isBelowMaxAge(MAX_AGE, Number(value)) ? null : `A idade deve ser menor que ${MAX_AGE}`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: null,
        }));
      }
    }
  };

  const hasErrors = Object.values(errors).some((error) => error);

  trackPage("plg-landing")

  const handleFetchProductsByAge = () => {
    trackAction('plg-landing-continue-button', {
      selectedAge: form.age,
    })

    if (form.email || form.cellphone) {
      mutateCreateLead({
        email: form.email,
        cellphone: unmaskPhone(form.cellphone),
      })
    } else {
      navigate(`/${form.age}/providers?state=${encodeURI("São Paulo")}`)
    }
  };

  useEffect(() => {
    if (params.partner) {
      setFromLS("utm_source", params.partner);
    }
  }, [])

  useEffect(() => {
    if (isSuccessLeadUseCase) {
      setFromLS('leadId', dataLeadUseCase.id);
      segmentIdentifyLead({
        id: dataLeadUseCase.id,
        cellphone: unmaskPhone(form.cellphone),
        email: form.email,
      })
      navigate(`/${form.age}/providers?state=${encodeURI("São Paulo")}`)
    }
  }, [isSuccessLeadUseCase])

  return {
    handleFetchProductsByAge,
    form,
    onChangeInput,
    hasErrors,
    errors,
  }
}
