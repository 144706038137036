import Divider from "../../../components/atoms/Divider/Divider";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import { convertPriceToMoney } from "../../../utils/masks";

const VoucherSection = ({ cashbackPrice, cashbackDuration }) => {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gapSize="10px"
      >
        <Typography
          variant="subheading2"
          fontWeight={500}
          color="terceary"
        >
          Cashback
        </Typography>
        <Typography
          variant="subheading1"
          fontWeight={600}
          color="terceary"
        >
          Receba de volta&nbsp;
          <span style={{ fontWeight: "bold" }}>
            {convertPriceToMoney(cashbackPrice)}
          </span> por parcela nos próximos {cashbackDuration} meses
        </Typography>  
      </Flex>
      <Divider />
    </>
  );
};

export default VoucherSection;
