import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { simulationsMutation } from "../useCases/simulations/mutations/simulationsMutation";
import { productsQueryUseCase } from "../../Products/useCases/products/mutations/productsMutation";
import { convertPriceToMoney } from "../../../utils/masks";
import { trackAction, trackPage } from "../../../utils/segment";
import {
  FaCheckCircle,
  FaChevronRight,
} from "react-icons/fa";
import { CiCircleMinus } from "react-icons/ci";
import Typography from "../../../components/atoms/Typography/Typography";
import Flex from "../../../components/atoms/Flex/Flex";
import { getFromLS, setFromLS } from "../../../utils/localStorage";
import { accreditNetworksQuery } from "../../Products/useCases/accreditNetworks/queries/accreditNetworksQuery";

export const compareProductsModel = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get('state');
  const productIds = queryParams.get('productId');
  const productGroupIds = queryParams.get('productGroupId');
  const dependentsAges = queryParams.get('dependentsAges');
  const voucher = queryParams.get('voucher');
  const { age } = useParams();
  const [dataTable, setDataTable] = useState([])
  const [productComparedSelected, setProductComparedSelected] = useState(productIds.split(",")[0]);
  const [productGroupIdsComparedSelected, setProductGroupIdsComparedSelected] = useState(productGroupIds.split(",")[0]);
  const [hospitalsModalVisibility, setHospitalsModalVisibility] = useState(false)
  const [laboratoriesModalVisibility, setLaboratoriesModalVisibility] = useState(false);
  const [productLaboratories, setProductLaboratories] = useState([])
  trackPage("plg-compare")

  const {
    mutateCreateSimulation,
    dataSimulationUseCase,
    isSuccessSimulationUseCase,
    isLoadingSimulationUseCase,
  } = simulationsMutation();

  const {
    accreditNetworksData,
    accreditNetworksIsSuccess,
    accreditNetworksIsLoading,
  } = accreditNetworksQuery({ productGroupIds });

  const {
    dataProductQueryUseCase,
    isLoadingProductQueryUseCase,
    isSuccessProductQueryUseCase,
  } = productsQueryUseCase({
    age,
    dependents: dependentsAges ? dependentsAges.split(",") : [],
    state,
    productGroupIds,
    providerIds: [],
  });

  const onClickSaveButton = () => {
    mutateCreateSimulation({
      age,
      products: dataProductQueryUseCase,
      selectedProductId: productComparedSelected,
      dependents: dependentsAges ? dependentsAges.split(",") : [],
      voucher,
      utm_source: getFromLS("utm_source"),
      leadId: getFromLS("leadId"),
    })
  };

  const redirectToResume = () => {
    const product = dataProductQueryUseCase.find(product => product.id === productComparedSelected)
    trackAction('plg-compare-continue-button', {
      age,
      ...product,
    })

    queryParams.delete("productId")
    queryParams.append("productId", productComparedSelected);
    queryParams.append("simulationId", dataSimulationUseCase);

    if(!state) {
      queryParams.append("state", state);
    }
    const url = `/${age}/providers/products/compare/resume?${queryParams.toString()}`;
    navigate(url)
  }

  const onClickHospitalMenu = () => {
    setHospitalsModalVisibility(true)
  }

  const onCloseHospitalMenu = () => {
    setHospitalsModalVisibility(false)
  }

  const onClickLaboratoryMenu = (laboratories) => {
    setProductLaboratories(laboratories)
    setLaboratoriesModalVisibility(true);
  }

  const onCloseLaboratoriesMenu = () => {
    setLaboratoriesModalVisibility(false)
  }
  const isPrivateAccommodation = (accomodation) => {
    if (accomodation === "private") {
      return (<FaCheckCircle color="#5900CC" />)
    }
    return (<CiCircleMinus color="#8B91B3" />)
  }

  const isSharedAccommodation = (accomodation) => {
    if (accomodation === "shared") {
      return (<FaCheckCircle color="#5900CC" />)
    }
    return (<CiCircleMinus color="#8B91B3" />)
  }

  const isWithCopayment = (copayment) => {
    if (copayment === "with") {
      return <FaCheckCircle color="#5900CC" />
    }
    return <CiCircleMinus color="#8B91B3" />
  }

  const isWithoutCopayment = (copayment) => {
    if (copayment !== "with") {
      return <FaCheckCircle color="#5900CC" />
    }
    return <CiCircleMinus color="#8B91B3" />
  }

  const getProductName = (name, price, providerName) => {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gapSize
      >
        <img
          src={`https://duca-assets.s3.amazonaws.com/providers/${providerName}.png`}
          style={{ maxWidth: 70, maxHeight: 32 }}
          alt={`${providerName} logo`}
        />
        <Typography
          variant={"subheading3"}
          fontWeight={600}
          color={"terceary"}
        >
          {name}
        </Typography>
        <Typography
          variant={"subheading2"}
          fontWeight={600}
          color={"primary"}
        >
          {convertPriceToMoney(price)}
        </Typography>
      </Flex>
    )
  }

  const getReimbursement = (reimbursement) => {
    return (
      <Typography
        variant={"subheading2"}
        fontWeight={600}
        color={"primary"}
      >
        {convertPriceToMoney(reimbursement)}
      </Typography>
    )
  }

  const getHospitals = (hospitals) => {
    return (
      <Flex
        onClick={onClickHospitalMenu}
        style={{ cursor: "pointer" }}
      >
        <Typography
          variant={"subheading2"}
          fontWeight={700}
          color={"primary"}
        >
          {hospitals.length} <FaChevronRight color="#5900CC" />
        </Typography>
      </Flex>
    )
  }

  const getLaboratories = (laboratories) => {
    return (
      <Flex
        onClick={() => onClickLaboratoryMenu(laboratories)}
        style={{ cursor: "pointer" }}
      >
        <Typography
          variant={"subheading2"}
          fontWeight={700}
          color={"primary"}
        >
          {laboratories.length} <FaChevronRight color="#5900CC" />
        </Typography>
      </Flex>
    )
  }

  const columns = [
    {
      name: 'productName',
      hidden: true,
    },
    {
      name: 'Quarto privado',
      hidden: false,
    },
    {
      name: 'Quarto compartilhado',
      hidden: false,
    },
    {
      name: 'Sem coparticipação',
      hidden: false,
    },
    {
      name: 'Com coparticipação',
      hidden: false,
    },
    {
      name: 'Reembolso',
      hidden: false,
    },
    {
      name: 'Hospitais',
      hidden: false,
    },
    {
      name: 'Laboratórios',
      hidden: false,
    },
  ];

  
  const onClickProduct = (productId) => {
    const product = dataProductQueryUseCase.find(product => product.id === productId);
    setProductComparedSelected(productId);
    setProductGroupIdsComparedSelected(product.productGroupId)
  }

  useEffect(() => {
    const utm_source = queryParams.get('utm_source');
    if (utm_source) {
      setFromLS("utm_source", utm_source)
    }
  }, [])

  useEffect(() => {
    if (!isLoadingSimulationUseCase && isSuccessSimulationUseCase) {
      redirectToResume();
    }
  }, [isSuccessSimulationUseCase, isLoadingSimulationUseCase])

  useEffect(() => {
    if (isSuccessProductQueryUseCase && accreditNetworksIsSuccess) {
      const data = dataProductQueryUseCase.map((product => {
        const accredit = accreditNetworksData.find(accredit => accredit.productGroupId === product.productGroupId)
        const sharedAccomodation = isSharedAccommodation(product.accommodation)
        const privateAccomodation = isPrivateAccommodation(product.accommodation)
        const withCopayment = isWithCopayment(product.copayment);
        const withoutCopayment = isWithoutCopayment(product.copayment);
        const reimbursement = getReimbursement(product.reimbursement)
        const hospitals = getHospitals(accredit.hospitals);
        const laboratories = getLaboratories(accredit.laboratories);
        return {
          'id': product.id,
          'productName': getProductName(product.name, product.price, product.provider.name),
          'Quarto privado': privateAccomodation,
          'Quarto compartilhado': sharedAccomodation,
          'Sem coparticipação': withoutCopayment,
          'Com coparticipação': withCopayment,
          'Reembolso': reimbursement,
          'Hospitais': hospitals,
          'Laboratórios': laboratories,
        }
      }))
      setDataTable(data);
    }
  }, [
    isSuccessProductQueryUseCase,
    accreditNetworksIsSuccess,
  ])

  return {
    columns,
    dataTable,
    onClickSaveButton,
    setProductComparedSelected,
    hospitalsModalVisibility,
    productComparedSelected,
    onCloseHospitalMenu,
    isLoading: isLoadingProductQueryUseCase || isLoadingSimulationUseCase || accreditNetworksIsLoading,
    productLaboratories,
    onCloseLaboratoriesMenu,
    laboratoriesModalVisibility,
    productGroupIdsComparedSelected,
    onClickProduct,
  }
}
