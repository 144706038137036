import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const ContractualInfoSection = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      style={{ marginTop: "36px" }}
    >
      <Typography
        variant="subheading2"
        fontWeight={400}
        color="terceary"
      >
        Ao clicar em contratar você entende e
        concorda que os valores apresentados podem
        sofrer alterações até o momento da assinatura
        do contrato.
      </Typography>
    </Flex>
  );
};

export default ContractualInfoSection;
