import Flex from "../atoms/Flex/Flex";
import Typography from "../atoms/Typography/Typography";
const Button = ({
  suffix,
  text,
  onClick,
  width = "320px",
  height = "40px",
  children,
  color = "white",
  style,
  disabled = false,
}) => {
  return (
    <Flex
      onClick={disabled ? null : onClick}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        borderWidth: "1.5px 1.5px",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        textAlign: "center",
        width,
        height,
        borderRadius: "8px",
        backgroundColor: disabled ? "#D5D9F2" : "#5900CC",
        boxShadow: disabled ? "" : "rgba(89, 0, 204, 1) 0px 0px 4px 0",
        fontWeight: "700",
        padding: "10px 20px",
        color,
        ...style,
      }}
    >
      <div></div>
      <Typography
        variant="subheading1"
        fontWeight={600}
        style={{ color: "white" }}
      >
        {text}
      </Typography>
      {suffix ? suffix : children}
    </Flex>
  );
};

export default Button;