import { isMobile } from "react-device-detect";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import { convertPriceToMoney } from "../../../utils/masks"
import { FaInfoCircle } from "react-icons/fa";
import {
  MdOutlineMonetizationOn,
  MdOutlineSingleBed,
} from "react-icons/md";

import { Tooltip } from 'react-tooltip';
import ducaPositiveLogo from '../../../assets/duca_positive.png';

import {
  FaAngleDown,
  FaAngleUp,
  FaHandHoldingUsd,
  FaStopwatch,
} from "react-icons/fa";
import TagDescription from "./TagDescription";

const CardProduct = ({
  accommodation,
  copayment,
  cashback,
  cashbackPrice,
  cashbackDuration,
  isSelectedProduct,
  name,
  onClickProduct,
  onClickViewInformation,
  price,
  providerName,
  productId,
  priceByAges,
  reimbursement,
  viewInformation,
}) => {
  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"left"}
      flexDirection={"column"}
      gapSize={"12px"}
      style={{
        width: isMobile ? "85%" : "500px",
        alignSelf: "center",
        height: "hug 270px",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        borderRadius: 8,
        padding: 12,
        cursor: "pointer",
        ...(isSelectedProduct && {
          border: "1px solid rgba(89, 0, 204, 1)",
        }),
      }}
      onClick={() => onClickProduct(productId)}
    >
      <Flex
        justifyContent={"space-between"}
        alignItems={"left"}
        flexDirection={"column"}
        style={{
          gap: "8px",
        }}
      >
        <div>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"} 
          >
            <img
              src={`https://duca-assets.s3.amazonaws.com/providers/${providerName}.png`}
              style={{ maxWidth: 60, maxHeight: 32, marginBottom: 12 }}
              alt={`${providerName} logo`}
            />
            <Flex
              onClick={onClickViewInformation}
              style={{
                padding: "5px",
                backgroundColor: "##F8F4FF",
              }}
            >
              {isMobile && (viewInformation ? <FaAngleUp/>:<FaAngleDown/>)}
            </Flex>
          </Flex>
          <Typography
            variant={"subheading1"}
            color={"info"}
            fontWeight={700}
          >
            {name}
          </Typography>
        </div>
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"left"}
          style={{
            display: viewInformation ? "flex" : "none",
            gap: 6,
          }}
        >
          <Flex
            gapSize={"sm"}
            style={{
              flexWrap: "wrap",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <TagDescription
              icon={<MdOutlineSingleBed style={{ color: "#181059" }}/>}
              description={accommodation}
              onClick={() => {}}
            />
            <TagDescription
              icon={<MdOutlineMonetizationOn style={{ color: "#181059" }}/>}
              description={copayment}
              onClick={() => {}}
            />
          </Flex>
          {Boolean(reimbursement) && (
            <Flex
              gapSize
              alignItems={"center"}
            >
              <FaHandHoldingUsd 
              style={{
                color: "#181059",
              }}/>
              <Typography
                variant={"subheading2"}
                fontWeight={600}
                color={"secondary"}
              >
                Reembolso até R$ {reimbursement}
              </Typography>
            </Flex>
          )}
          <Flex
            alignItems={"center"}
            style={{ gap: 4 }}
          >
            <FaStopwatch style={{ color: "#181059" }}/>
            <Typography
              variant={"subheading2"}
              fontWeight={600}
              color={"secondary"}
            >
              Carência
            </Typography>
            <a
              style={{ alignItems: "center" }}
              data-tooltip-id="my-tooltip-multiline"
              data-tooltip-html="Sem plano?<br/>
                Carência padrão:<br/>
                  •	Consultas: 0 a 30 dias<br/>
                  •	Exames simples: 0 a 90 dias<br/>
                  •	Outros procedimentos: 180 dias<br/>
                <br/>
                Urgência ou emergência?</br>
                Disponível em 24 horas após ativação </br>
                do plano para todos procedimentos </br>
                decorrentes deste atendimento."
            >
              <FaInfoCircle
                style={{
                  marginRight:"5px",
                  color: "#181059",
                  alignSelf: "center",
                }}
              />
            </a>
            <Tooltip id="my-tooltip-multiline" />
          </Flex>
          <Flex
            alignItems={"center"}
          >
            <Typography
              fontWeight={700}
              color={"info"}
              variant={"subheading1"}
            >
              R$&nbsp;
            </Typography>
            <Typography
              fontWeight={700}
              color={"info"}
              variant={"h6"}
            >
              {price}
            </Typography>
            <Typography
              variant={"subheading2"}
              fontWeight={700}
              color={"info"}
            >
              /mês
            </Typography>
          </Flex>
          <Typography
            variant={"subheading2"}
            fontWeight={700}
            color={"secondary"}
          >
            Composição do preço deste plano:
          </Typography>
          <Flex flexDirection={"column"}>
            {priceByAges.map((d, i) => (
              <Typography
                key={i}
                variant={"subheading2"}
                fontWeight={500}
                color={"secondary"}
              >
                {convertPriceToMoney(d.price)} - {d.age} anos
              </Typography>
            ))}
          </Flex>
        </Flex>
        {Boolean(!viewInformation) && (
          <Flex alignItems={"center"}>
            <Typography
              fontWeight={700}
              color={"info"}
              variant={"subheading1"}
            >
              R$&nbsp;
            </Typography>
            <Typography
              fontWeight={700}
              color={"info"}
              variant={"h6"}
            >
              {price}
            </Typography>
            <Typography
              variant={"subheading2"}
              fontWeight={700}
              color={"info"}
            >
              /mês
            </Typography>
          </Flex>
        )}
        {Boolean(cashback) && (
          <Flex
            style={{
              backgroundColor: "#F4F1FC",
              borderRadius: 4,
              alignItems: "center",
            }}
          >
            <Flex>
              <img
                src={ducaPositiveLogo}
                style={{ maxWidth: 90, maxHeight: 52 }}
                alt="Duca logo"
              />
            </Flex>
            <Flex
              flexDirection={"column"}
              style={{ gap: 2 }}
            >
              <Typography
                variant="subheading3"
                fontWeight={700}
                color={"secondary"}
              >
                te devolvemos&nbsp;
                <span style={{ color: "#5900CC", fontSize: 12 }}>
                  {convertPriceToMoney(cashbackPrice)}
                </span>
                &nbsp;durante&nbsp;
                <span style={{ color: "#5900CC", fontSize: 12 }}>
                  {cashbackDuration}&nbsp;meses
                </span>
              </Typography>
              <Typography
                variant="subheading3"
                fontWeight={700}
                color={"secondary"}
              >
                {cashback}% de cashback&nbsp;
                <span style={{ color: "#5900CC", fontSize: 12 }}>
                  Duca Saúde
                </span>
              </Typography>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default CardProduct;