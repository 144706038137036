import { isMobile } from "react-device-detect";
import Typography from "../../../../components/atoms/Typography/Typography";

export const CompareTable = ({
  columns,
  data,
  productComparedSelected,
  onClickProduct,
}) => {
  return (
    <div style={{
      display: "flex",
      overflowX: isMobile ? "auto" : "hidden",
      justifyContent: isMobile ? "flex-start" : "center",
      width: "100%",
    }}>
      <div style={{ overflowX: "auto" }}>
        <table
          style={{
            marginBottom: 50,
            marginRight: 80,
          }}
        >
          <tbody
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {columns.map((column, index) => (
              <tr
                key={index}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "70px",
                }}
              >
                <th style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: "150px",
                  padding: '0',
                  position: "sticky",
                  background: "white",
                  left: 0,
                  zIndex: 1,
                }}>
                  {!column.hidden && (
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      alignItems: "center",
                      backgroundColor: index > 0 && "#F8F4FF",
                      height: "60%",
                      borderTopLeftRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Typography
                      variant={"subheading2"}
                      fontWeight={600}
                      color={"terceary"}
                      style={{
                        padding: "12px 24px",
                        textAlign: 'left',
                      }}
                    >
                      {column.name}
                    </Typography>
                    </div>
                  )}
                </th>
                {data.map((entry, dataIndex) => (
                  <td
                    key={dataIndex}
                    onClick={() => onClickProduct(entry.id)}
                    style={{
                      display: "flex",
                      width: "110px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      ...(entry.id === productComparedSelected && {
                        ...(column.name === "productName" && {
                          zIndex: -1,
                          borderTop: "1px solid rgba(89, 0, 204, 1)",
                          borderTopRightRadius: "10px 10px",
                          borderTopLeftRadius: "10px 10px",
                        }),
                        ...(column.name === "Laboratórios" && {
                          borderBottom: "1px solid rgba(89, 0, 204, 1)",
                          borderBottomLeftRadius: "10px 10px",
                          borderBottomRightRadius: "10px 10px",
                        }),
                        borderRight: "solid rgba(89, 0, 204, 1)",
                        borderLeft: "solid rgba(89, 0, 204, 1)",
                        borderWidth: "1px 1px",
                      }),
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        backgroundColor: index > 0 && "#F8F4FF",
                        height: "60%",
                      }}
                    >
                      {entry[column.name]}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
