import Typography from '../../atoms/Typography/Typography';
import Button from '../../molecules/Button';
import StateFilter from './components/StateFilter';
import { allFiltersModel } from './viewModel/allFiltersModel';
import PriceFilter from './components/PriceFilter';
import Flex from '../../atoms/Flex/Flex';
import HospitalFilter from './components/HospitalFilter';
import Loading from '../../molecules/Loading/Loading'
import Drawer from '../../molecules/Drawer'
import Divider from '../../atoms/Divider/Divider'
import { ReactComponent as DrawerRectanbleIcon } from '../../../assets/drawer-rectangle.svg';
import AccommodationFilter from './components/AccommodationFilter';
import CopaymentFilter from './components/CopaymentFilter';
const AllFilters = ({
  onClose,
  visible,
  hospitals,
  onChangeStateMutation,
  onChangeHospitals,
  isLoading,
  onClearHospitals,
  nextBaseUrl,
}) => {
  const {
    states,
    selectedState,
    onClickFilterSubmit,
    onChangePrice,
    currentPrice,
    onSelectHospital,
    handleChange,
    accommodation,
    handleAccommodationChange,
    copayments,
    handleCopaymentChange,
  } = allFiltersModel({
    onChangeStateMutation,
    onClose,
    onChangeHospitals,
    nextBaseUrl,
  })

  return (
    <Drawer
      visible={visible}
      onClose={onClickFilterSubmit}
      size={"lg"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
      >
        <DrawerRectanbleIcon style={{ marginTop: 36 }}/>
        <Typography
          variant={"h6"}
          fontWeight={400}
          color={"info"}
          style={{ alignSelf: "center", marginTop: 30 }}
        >
          Filtros
        </Typography>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ width: "100%", overflowY: "auto", paddingTop: 200 }}
      >
        {isLoading && (<Loading/>)}
        <Flex
          flexDirection={"column"}
          gapSize={"lg"}
          style={{ width: "90%" }}
        >
          <StateFilter
            states={states}
            handleChange={handleChange}
            selectedState={selectedState}
          />
          <Divider/>
          <AccommodationFilter
            handleAccommodationChange={handleAccommodationChange}
            accommodation={accommodation}
          />
          <Divider/>
          <CopaymentFilter
            copayments={copayments}
            handleCopaymentChange={handleCopaymentChange}
          />
          <Divider/>
          <HospitalFilter
            hospitals={hospitals}
            onSelectHospital={onSelectHospital}
            onClearHospitals={onClearHospitals}
            isLoading={isLoading}
          />
          <Divider/>
          <PriceFilter
            currentPrice={currentPrice}
            onChangePrice={onChangePrice}
          />
        </Flex>
      </Flex>
      <Button
        text={"Aplicar filtros"}
        onClick={onClickFilterSubmit}
        width={"250px"}
        height={"20px"}
        disabled={isLoading}
      />
    </Drawer>
  )
}

export default AllFilters;