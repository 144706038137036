import Flex from "../../../atoms/Flex/Flex"
import Typography from "../../../atoms/Typography/Typography"

const CopaymentFilter = ({
  copayments,
  handleCopaymentChange,
}) => {
  return (
    <Flex flexDirection={"column"} gapSize>
      <Typography
        variant={"subheading1"}
        fontWeight={700}
        color={"terceary"}
      >
        Coparticipação
      </Typography>
      <Flex
        justifyContent={"space-between"}
        onClick={() => handleCopaymentChange("without")}
      >
        <Typography
          variant={"subheading1"}
          fontWeight={400}
          color={"terceary"}
        >
          Sem Coparticipação
        </Typography>
        <input
          type="checkbox"
          style={{ accentColor: "#5900CC" }}
          checked={copayments.without}
        />
      </Flex>
      <Flex
        justifyContent={"space-between"}
        onClick={() => handleCopaymentChange("with_partial")}
      >
        <Typography
          variant={"subheading1"}
          fontWeight={400}
          color={"terceary"}
        >
          Coparticipação parcial
        </Typography>
        <input
          type="checkbox"
          style={{ accentColor: "#5900CC" }}
          checked={copayments.with_partial}
        />
      </Flex>
      <Flex
        justifyContent={"space-between"}
        onClick={() => handleCopaymentChange("with")}
      >
        <Typography
          variant={"subheading1"}
          fontWeight={400}
          color={"terceary"}
        >
          Coparticipação total
        </Typography>
        <input
          type="checkbox"
          style={{ accentColor: "#5900CC" }}
          checked={copayments.with}
        />
      </Flex>
    </Flex>
  )
}

export default CopaymentFilter