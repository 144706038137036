import Button from "./Button";

const ButtonFixed = ({
  onClick,
  disabled = false,
  text = "",
}) => {
  return (
    <Button
      text={text || "Contratar"}
      width="250px"
      height="20px"
      onClick={onClick}
      disabled={disabled}
      style={{
        position: "fixed",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1000,
      }}
    />
  );
};

export default ButtonFixed;
