import { useEffect, useState } from "react";
import { hospitalsQueryUseCase } from "../useCases/queries/hospitalsQuery";
import { groupBy } from "lodash";
import { capitalizeFirstLetter } from "../../../utils/masks";

export const hospitalsModel = ({ productGroupId }) => {
  const [shouldDisplayZone, setShouldDisplayZone] = useState({})
  const [hospitalInputValue, setHospitalInputValue] = useState("")
  const [builtHospitals, setBuiltHospitals] = useState([])

  const {
    hospitalsData,
  } = hospitalsQueryUseCase({
    productGroupId,
  });

  const getServiceName = (service) => {
    switch (service) {
      case "hospital":
        return "Hospital"
      case "maternity":
        return "Maternidade"
      case "emergency_room":
        return "Pronto Socorro"
      case "low_complexity":
        return "Baixa complexidade"
      case "ambulatory":
        return "Ambulatório"
      default:
        return ""
    }
  }
  const buildHospitals = (hospitals) => {
    if (!hospitals) return []

    const priorityZones = ["zona norte", "zona oeste", "zona sul", "zona leste", "centro"];

    const sortedData = [
      ...hospitals
        .filter(item => priorityZones.includes(item.zone))
        .sort((a, b) => priorityZones.indexOf(a.zone) - priorityZones.indexOf(b.zone)),
      ...hospitals
        .filter(item => !priorityZones.includes(item.zone))
        .sort((a, b) => a.zone.localeCompare(b.zone)),
    ];

    const filteredData = hospitalInputValue ?
      sortedData.filter(item => item.name.toLocaleLowerCase().includes(hospitalInputValue.toLocaleLowerCase()))
      : sortedData

    const hospitalsParsed = filteredData.map(hospital => {
      const hospitalZone = capitalizeFirstLetter(hospital.zone)

      const services = hospital.services?.map(service => getServiceName(service))
      return {
        ...hospital,
        zone: hospitalZone,
        services,
      }
    })
    const hospitalsGroupedByZone = groupBy(hospitalsParsed, 'zone')
    setBuiltHospitals(hospitalsGroupedByZone)
  }

  const onClickHospitalZone = (hospitalZone) => {
    setShouldDisplayZone({
      ...shouldDisplayZone,
      [hospitalZone]: !shouldDisplayZone[hospitalZone],
    })
  }

  useEffect(() => {
    buildHospitals(hospitalsData)
  }, [hospitalInputValue, hospitalsData])

  return {
    shouldDisplayZone,
    hospitalsByZone: builtHospitals,
    onClickHospitalZone,
    setHospitalInputValue,
    hospitalInputValue,
  }
}