import Divider from "../../../components/atoms/Divider/Divider";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const InsuranceProviderSection = ({ name }) => {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gapSize="10px"
      >
        <Typography
          variant="subheading2"
          fontWeight={500}
          color="terceary"
        >
          Seguradora
        </Typography>
        <Typography
          variant="subheading1"
          fontWeight={600}
          color="terceary"
        >
          {name}
        </Typography>  
      </Flex>
      <Divider />
    </>
  );
};

export default InsuranceProviderSection;
