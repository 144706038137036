import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const SummarySection = ({ product }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      gapSize
      style={{
        backgroundColor: "#F5F5FE",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <div>
        <Typography
          variant="subheading1"
          fontWeight={500}
          color="terceary"
        >
          {product.provider.name}
        </Typography>
        <Typography
          variant="h6"
          fontWeight={700}
          color="terceary"
        >
          {product.name}
        </Typography>
      </div>
      <div> 
        <Typography
          variant="subheading2"
          fontWeight={400}
          color="terceary"
        >
          Valor total
        </Typography>
        <Typography
          variant="h6"
          fontWeight={500}
          color="terceary"
        >
          {product.price} /mês
        </Typography>
      </div>
    </Flex>
  );
};

export default SummarySection;
