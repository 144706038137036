export const trackPage = (page) => {
  analytics.page(page);
}

export const trackAction = (page, content) => {
  analytics.track(page, content);
}

export const segmentIdentify = (nanoid) => {
  analytics.identify(nanoid);
}

export const segmentIdentifyLead = ({ id, cellphone, email }) => {
  analytics.identify(id, {
    email,
    cellphone,
  });
}