import { MdClose } from "react-icons/md";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Warning from "../../../components/molecules/Warning";
import Button from "../../../components/molecules/Button";

const ProvidersLifeWarning = ({
  openMinimumLifeCheck,
  onClickCloseMinimumLifeCheck,
  onClickAddLifesFromWarning,
  warningMessage,
}) => {
  return (
    <Warning
      visible={openMinimumLifeCheck}
      onClose={onClickCloseMinimumLifeCheck}
      size={"sm"}
    >
      <Flex flexDirection={"column"} style={{ width: "100%" }}>
        <MdClose
          size={24}
          onClick={onClickCloseMinimumLifeCheck}
          style={{
            display: "flex",
            alignSelf: "flex-end",
            cursor: "pointer",
          }}
        />
        <Typography
          variant="h6"
          fontWeight={400}
          color={"info"}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Ooops
        </Typography>
      </Flex>
      <Typography
        variant="subheading1"
        fontWeight={400}
        color={"info"}
      >
        {warningMessage}
      </Typography>
      <Button
        text={"Adicionar vidas"}
        onClick={onClickAddLifesFromWarning}
        width={"250px"}
        height={"20px"}
        disabled={false}
      />
    </Warning>
  )
}

export default ProvidersLifeWarning;