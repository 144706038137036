import 'rc-slider/assets/index.css';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Flex from '../../../atoms/Flex/Flex';
import Typography from '../../../atoms/Typography/Typography';
import SearchableSelect from '../../../molecules/SearchableSelect';

const HospitalFilter = ({
  hospitals = [],
  onClearHospitals,
  onSelectHospital,
  isLoading,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      style={{
        width: "100%",
        zIndex: 9,
      }}
    >
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        style={{
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "27px",
          letterSpacing: "0em",
          textAlign: "left",
          margin: "6px",
          marginBottom: "0px",
        }}
      >
        <Flex flexDirection={"row"}>
          <Typography
            variant={"subheading1"}
            fontWeight={700}
            color={"terceary"}
          >
            Hospitais
          </Typography>
        </Flex>
      </Flex>
      <SearchableSelect
        data={hospitals}
        onSelectHospital={onSelectHospital}
        onClearHospitals={onClearHospitals}
        isLoading={isLoading}
      />
    </Flex>
  )
}

export default HospitalFilter;