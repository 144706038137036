import { isMobile } from "react-device-detect";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import ProviderCard from "./ProviderCard";
import Skeleton from 'react-loading-skeleton';

const ProviderList = ({
  isLoading,
  providersProducts,
  getLifeNumber,
  handleProvider,
  isCheckedProvider,
}) => {
  if (isLoading) {
    return (
      <Skeleton
        count={5}
        height={72}
        style={{
          width: "750px",
          marginTop: "10px",
          ...(isMobile && {
            width: "370px",
          }),
        }}
      />
    );
  }

  if (!providersProducts.length) {
    return (
      <Typography
        variant="subheading1"
        fontWeight={700}
        color="terceary"
      >
        Não foi encontrado nenhum plano de saúde, tente usar outro filtro.
      </Typography>
    );
  }

  return (
    <Flex
      flexDirection="column"
      gapSize
    >
      {providersProducts.map(([providerId, products]) => (
        <ProviderCard
          key={providerId}
          providerId={providerId}
          product={products[0]}
          lifeNumber={getLifeNumber(products[0].provider.minLifes)}
          onClick={() => handleProvider(providerId)}
          checkedProvider={isCheckedProvider(providerId)}
        />
      ))}
    </Flex>
  );
};

export default ProviderList;
