import { useParams } from "react-router-dom";
import { productsQueryUseCase } from "../../Products/useCases/products/mutations/productsMutation";
import {
  getAccommodation,
  getAdherence,
  getCopayment,
  getProviderName,
} from "../../../utils/i18n-product";
import {
  convertPriceToMoney,
} from "../../../utils/masks"
import { trackAction, trackPage } from "../../../utils/segment";
import { vouchersQuery } from "../../../components/organisms/VoucherFilter/useCases/queries/getVouchersQuery";
import { useEffect, useState } from "react";
import { setFromLS } from "../../../utils/localStorage";
import { accreditNetworksQuery } from "../../Products/useCases/accreditNetworks/queries/accreditNetworksQuery";
export const resumeModel = () => {
  const [providersProducts, setProvidersProducts] = useState();
  const [hospitalsModalVisibility, setHospitalsModalVisibility] = useState(false)
  const [laboratoriesModalVisibility, setLaboratoriesModalVisibility] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const productIds = queryParams.get('productId');
  const productGroupIds = queryParams.get('productGroupId');
  const state = queryParams.get('state');
  const simulationId = queryParams.get('simulationId');
  const dependentsAges = queryParams.get('dependentsAges');
  const voucher = queryParams.get('voucher');
  const { age } = useParams();
  trackPage("plg-resume")
  /* eslint-disable */
  const {
    dataVouchersUseCase,
    isLoadingVouchersUseCase,
    isSuccessVouchersUseCase,
  } = vouchersQuery({ code: voucher });

  const {
    accreditNetworksData,
    accreditNetworksIsSuccess,
    accreditNetworksIsLoading,
  } = accreditNetworksQuery({ productGroupIds });

  const {
    dataProductQueryUseCase,
    isLoadingProductQueryUseCase,
    isSuccessProductQueryUseCase,
  } = productsQueryUseCase({
    age,
    dependents: dependentsAges ? dependentsAges.split(",") : [],
    state,
    productGroupIds,
    providerIds: [],
  });

  const getAllAccreditedNetwork = (hospitals,laboratories) => {
    return hospitals + laboratories;
  }

  const redirectToLogin = (e) => {
    e.preventDefault();
    const product = dataProductQueryUseCase.find(product => product.id === productIds)
    trackAction('plg-resume-continue-button', {
      age,
      ...product,
    })

    const url = `https://app.ducasaude.com.br/?simulationId=${simulationId}`;
    window.location.href = url;
  }

  const buildDependentsText = () => {
    if (dependentsAges) {
      const dependents = dependentsAges.split(",");
      if (dependents.length > 1) {
        return `${dependents.length} vidas - ${dependents.join(" e ")} anos`
      }
      return `${dependents.length} vida - ${dependents[0]} anos`
    }
    return ""
  }

  const buildHolderText = () => {
    return `1 titular - ${age} anos`
  }

  const onClickTalkToUs = () => {
    const product = dataProductQueryUseCase.find(product => product.id === productIds)
    const productAgesSelected = product.pricesByAge.map(p => p.age).join(",")
    const totalValue = product.pricesByAge.reduce((acc, p) => acc + p.price, 0)
    const message = `
      Simulei o plano ${product.name}  para as idades ${productAgesSelected}, no valor de ${convertPriceToMoney(totalValue)}, e gostaria de ajuda
      Código da simulação: ${simulationId}`;
    const url = `https://api.whatsapp.com/send?phone=5521971779781&text=${encodeURI(message)}&app_absent=0`;
    window.open(url);
  }

  const getVoucher = (vouchers) => {
    if (!vouchers) return

    if (!vouchers.length) return

    return vouchers[0].cashback.find(voucher =>
      voucher.productId === product.id,
    )
  }

  const parseProductByVoucher = (product, vouchers, accreditNetwork) => {
    const voucherFound = getVoucher(vouchers)

    return {
      id: product.id,
      name: product.name,
      provider: {
        id: product.provider.id,
        name: getProviderName(product.provider.name),
      },
      copayment: getCopayment(product.copayment),
      state: product.state,
      adherence: getAdherence(product.adherence),
      accommodation: getAccommodation(product.accommodation),
      reimbursement: convertPriceToMoney(product.reimbursement),
      price: convertPriceToMoney(product.price),
      hospitals: accreditNetwork.hospitals,
      laboratories: accreditNetwork.laboratories,
      productGroupId: product.productGroupId,
      ...(voucherFound && {
        cashback: voucherFound ? voucherFound.percentage : undefined,
        cashbackDuration: voucherFound ? voucherFound.months : undefined,
        cashbackPrice: voucherFound ?
          product.price * (voucherFound.percentage / 100)
          : 0,
      })
    }
  }

  const onClickHospitalMenu = () => {
    setHospitalsModalVisibility(true)
  }

  const onCloseHospitalMenu = () => {
    setHospitalsModalVisibility(false)
  }

  const onClickLaboratoryMenu = () => {
    setLaboratoriesModalVisibility(true);
  }

  const onCloseLaboratoriesMenu = () => {
    setLaboratoriesModalVisibility(false)
  }

  useEffect(() => {
    const utm_source = queryParams.get('utm_source');
    if (utm_source) {
      setFromLS("utm_source", utm_source)
    }
  }, [])

  useEffect(() => {
    if (isSuccessProductQueryUseCase && isSuccessVouchersUseCase && accreditNetworksIsSuccess) {
      const productWithVoucher = parseProductByVoucher(
        dataProductQueryUseCase[0],
        dataVouchersUseCase[0],
        accreditNetworksData[0],
      )
      setProvidersProducts(productWithVoucher)
    }
  }, [
    isSuccessVouchersUseCase,
    isSuccessProductQueryUseCase,
    accreditNetworksIsSuccess,
  ])

  return {
    product: providersProducts,
    getAllAccreditedNetwork,
    isLoading: isLoadingVouchersUseCase && isLoadingProductQueryUseCase && accreditNetworksIsLoading,
    redirectToLogin,
    buildDependentsText,
    buildHolderText,
    dependentsAges,
    onClickHospitalMenu,
    onClickLaboratoryMenu,
    onClickTalkToUs,
    hospitalsModalVisibility,
    onCloseHospitalMenu,
    onCloseLaboratoriesMenu,
    laboratoriesModalVisibility,
    laboratories: accreditNetworksData ? accreditNetworksData[0].laboratories : {},
  };
};
