import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { vouchersMutation } from "../useCases/queries/getVouchersQuery"
import { useEffect } from "react";
export const voucherFiltersModel = ({
  onClose,
  nextBaseUrl,
}) => {
  const [voucherInput, setVoucherInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { age } = useParams();

  const {
    dataVouchersUseCase,
    isLoadingVouchersUseCase,
    isSuccessVouchersUseCase,
    getVouchersUseCase,
  } = vouchersMutation();

  const onSuccessVoucher = () => {
    queryParams.delete("voucher")
    if (voucherInput) {
      queryParams.append("voucher", voucherInput);
    }
    const url = `/${age}/${nextBaseUrl}?${queryParams.toString()}`;
    navigate(url)
    onClose();
  }

  const onClickVerifyVoucher = () => {
    getVouchersUseCase({ code: voucherInput })
  }

  const onChangeInput = (e) => {
    const value = String(e.target.value).toUpperCase()
    if (value.length > 20) return voucherInput
    setVoucherInput(value);
  }

  useEffect(() => {
    if (!isLoadingVouchersUseCase && isSuccessVouchersUseCase) {
      if (!dataVouchersUseCase.length) {
        setErrorMessage("Código inválido!");
      } else {
        setErrorMessage("")
        onSuccessVoucher()
      }
    }
  }, [isLoadingVouchersUseCase, isSuccessVouchersUseCase])
  return {
    onChangeInput,
    voucherInput,
    onClickVerifyVoucher,
    errorMessage,
    isLoading: isLoadingVouchersUseCase,
    disabled: !voucherInput,
    onSuccessVoucher,
  }
}