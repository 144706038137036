import axios from "axios";
import { useMutation, useQuery } from 'react-query';
import { API } from "../../../../../utils/http";
import { buildQueryParams } from "../../../../../utils/utils";

async function getProductsByAgeWithDependents({
  age,
  dependents,
  state,
  maxPrice,
  productIds,
  providerIds,
  copayment,
  accommodation,
  productGroupIds,
}) {

  const params = {
    age,
    dependents: dependents.join(","),
    providerIds: providerIds.join(","),
    state,
    maxPrice: maxPrice ? Number(maxPrice) * 100 : '',
    productIds: productIds ?? '',
    copayment,
    accommodation,
    productGroupIds,
  };

  const queryString = buildQueryParams(params);

  const response = await axios.get(`${API}/products?${queryString}`);

  return response.data;
}

export const productsMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => getProductsByAgeWithDependents(e),
  );

  return {
    isSuccessProductUseCase: isSuccess,
    dataProductUseCase: data,
    isLoadingProductUseCase: isLoading,
    errorProductUseCase: error,
    refetchWithAgeAndDependents: mutate,
  }
}

export const productsQueryUseCase = ({
  age,
  dependents,
  state,
  maxPrice,
  productIds,
  providerIds,
  copayment,
  accommodation,
  productGroupIds,
}) => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    refetch,
  } = useQuery(
    [
      "productsQueryUseCase",
      age,
      dependents.join(),
      state,
      maxPrice,
      productIds,
      providerIds.join(),
      copayment,
      accommodation,
      productGroupIds,
    ],
    () => getProductsByAgeWithDependents({
      age,
      dependents,
      state,
      maxPrice,
      productIds,
      providerIds,
      copayment,
      accommodation,
      productGroupIds,
    }),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    },
  );

  return {
    isSuccessProductQueryUseCase: isSuccess,
    dataProductQueryUseCase: data,
    isLoadingProductQueryUseCase: isLoading,
    errorProductQueryUseCase: error,
    refetch,
  }
}