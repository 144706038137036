import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import LandingPageRoute from './pages/LandingPage/LandingPageRoute';
import ProvidersRoute from './pages/Providers/ProvidersRoute';
import Menu from './components/molecules/Menu';
import ProductsRoute from './pages/Products/ProductsRoute';
import CompareProductsRoute from './pages/CompareProducts/CompareProductsRoute';
import ResumeRoute from './pages/Resume/ResumeRoute';


const router = createBrowserRouter(
  [
    {
      element: <Menu/>,
      children: [
        {
          path: '/',
          element: <LandingPageRoute/>,
          errorElement: <LandingPageRoute/>,
        },
        {
          path: '/:partner',
          element: <LandingPageRoute/>,
          errorElement: <LandingPageRoute/>,
        },
        {
          path: '/:age/providers',
          element: <ProvidersRoute/>,
          errorElement: <LandingPageRoute/>,
        },
        {
          path: '/:age/providers/products',
          element: <ProductsRoute/>,
          errorElement: <LandingPageRoute/>,
        },
        {
          path: '/:age/providers/products/compare',
          element: <CompareProductsRoute/>,
        },
        {
          path: '/:age/providers/products/compare/resume',
          element: <ResumeRoute/>,
          errorElement: <LandingPageRoute/>,
        },
        {
          path: '*',
          element: <Navigate to="/" />,
          errorElement: <LandingPageRoute/>,
        },
      ],
    },
  ],
);

const AppRoutes = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default AppRoutes
