import Flex from "../../../atoms/Flex/Flex"
import Typography from "../../../atoms/Typography/Typography"

const AccommodationFilter = ({
  handleAccommodationChange,
  accommodation,
}) => {
  return (
    <Flex flexDirection={"column"} gapSize>
      <Typography
        variant={"subheading1"}
        fontWeight={700}
        color={"terceary"}
      >
        Tipos de Acomodação
      </Typography>
      <Flex
        justifyContent={"space-between"}
        onClick={() => handleAccommodationChange("private")}
      >
        <Typography
          variant={"subheading1"}
          fontWeight={400}
          color={"terceary"}
        >
          Quarto privado
        </Typography>
        <input
          type="checkbox"
          style={{ accentColor: "#5900CC" }}
          checked={accommodation.private}
        />
      </Flex>
      <Flex
        justifyContent={"space-between"}
        onClick={() => handleAccommodationChange("shared")}
      >
        <Typography
          variant={"subheading1"}
          fontWeight={400}
          color={"terceary"}
        >
          Quarto compartilhado
        </Typography>
        <input
          type="checkbox"
          checked={accommodation.shared}
          style={{ accentColor: "#5900CC" }}
        />
      </Flex>
    </Flex>
  )
}

export default AccommodationFilter