import { IoFilterOutline } from "react-icons/io5";
import Flex from "../atoms/Flex/Flex";
import { MdOutlineGroupAdd } from "react-icons/md";
import Typography from "../atoms/Typography/Typography";

const ButtonsFixedDesktop = ({
  onClickAllFiltersOpen,
  onClickLifeManagementFilterOpen,
  onClickContinueButton,
  disabled = true,
  text,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      gapSize={"lg"}
      style={{
        position: "fixed",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1000,
        width: "100%",
      }}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        style={{ width: "100%" }}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          onClick={onClickAllFiltersOpen}
          gapSize={"sm"}
          style={{
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
            padding: "12px 0px",
            border: "1px solid #5900CC",
            boxShadow: "0px 4px 4px 0px rgba(24, 16, 89, 0.15)",
            background: "white",
            minWidth: "40%",
            maxWidth: "40%",
            textWrap: "nowrap",
          }}
        >
          <IoFilterOutline size={16} color={"#5900CC"}/>
          <Typography
            variant="subheading1"
            fontWeight={600}
            color={"primary"}
          >
            Filtrar Planos
          </Typography>
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          onClick={onClickLifeManagementFilterOpen}
          gapSize={"sm"}
          style={{
            borderTopRightRadius: 30,
            borderBottomRightRadius: 30,
            padding: "12px 0px",
            border: "1px solid #5900CC",
            boxShadow: "0px 4px 4px 0px rgba(24, 16, 89, 0.15)",
            background: "white",
            minWidth: "40%",
            maxWidth: "40%",
          }}
        >
          <MdOutlineGroupAdd size={16} color={"#5900CC"}/>
          <Typography
            variant="subheading1"
            fontWeight={600}
            color={"primary"}
          >
            Add Vidas
          </Typography>
        </Flex>
      </Flex>
      <Flex
        onClick={disabled ? null : onClickContinueButton}
        alignItems={"center"}
        justifyContent={"center"}
        style={{
          width: "100%",
          height: 50,
          background: disabled ? "#D5D9F2" : "#5900CC",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        <Typography
          variant="subheading1"
          fontWeight={600}
          color={"neutral"}
        >
          {text}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default ButtonsFixedDesktop;
