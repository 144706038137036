import Divider from "../../../components/atoms/Divider/Divider"
import Flex from "../../../components/atoms/Flex/Flex"
import Typography from "../../../components/atoms/Typography/Typography"
import CashbackSection from "./CashbackSection"
import FiltersSelectedSectionMobile from "./FiltersSelectedSectionMobile"

const FiltersResumeMobile = ({
  stateSelected,
  lifeQuantity,
  copayment,
  accommodation,
  onClickAllFiltersOpen,
  onClickVoucherFilterOpen,
  title,
  subtitle,
}) => {
  return (
    <Flex flexDirection={"column"}>
      <FiltersSelectedSectionMobile
        state={stateSelected}
        lifesQuantity={lifeQuantity}
        copayment={copayment}
        accommodation={accommodation}
        onClick={onClickAllFiltersOpen}
      />
      <Divider/>
      <Flex
        flexDirection={"column"}
        gapSize
        style={{
          width: "90%",
          alignSelf: "center",
        }}
      >
        <Typography
          variant="subheading1"
          fontWeight={600}
          color="info"
          style={{ marginTop: 20 }}
        >
          {title}
        </Typography>
        <Typography
          variant="subheading2"
          fontWeight={500}
          color="secondary"
        >
          {subtitle}
        </Typography>
      </Flex>
      <CashbackSection onClick={onClickVoucherFilterOpen}/>
    </Flex>
  )
}

export default FiltersResumeMobile;