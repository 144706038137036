import Flex from "../../components/atoms/Flex/Flex"
import Loading from "../../components/molecules/Loading/Loading";
import { compareProductsModel } from "./viewModel/compareProductsModel";
import ButtonFixed from "../../components/molecules/ButtonFixed";
import { CompareTable } from "./components/CompareTable/CompareTable";
import Typography from "../../components/atoms/Typography/Typography";
import HospitalsRoute from "../Hospitals/HospitalsRoute";
import LaboratoriesRoute from "../Laboratories/LaboratoriesRoute";

const CompareProductsRoute = () => {
  const {
    columns,
    dataTable,
    onClickSaveButton,
    productComparedSelected,
    isLoading,
    hospitalsModalVisibility,
    onCloseHospitalMenu,
    productLaboratories,
    onCloseLaboratoriesMenu,
    laboratoriesModalVisibility,
    productGroupIdsComparedSelected,
    onClickProduct,
  } = compareProductsModel();

  if (isLoading) {
    return (
      <Loading/>
    )
  }
  return (
    <Flex
      flexDirection={"column"}
      style={{
        padding: "20px",
        height: "100%",
        marginTop: "40px",
        paddingBottom: 80,
      }}
    >
      <Typography
        variant={"h5"}
        fontWeight={400}
        color={"terceary"}
        style={{
          marginBottom: 16,
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        Selecione o melhor plano para você!
      </Typography>
      <CompareTable
        columns={columns}
        data={dataTable}
        onClickProduct={onClickProduct}
        productComparedSelected={productComparedSelected}
      />
      <ButtonFixed onClick={onClickSaveButton} text={"Continuar"}/>
      <HospitalsRoute
        productGroupId={productGroupIdsComparedSelected}
        visible={hospitalsModalVisibility}
        onClose={onCloseHospitalMenu}
      />
      <LaboratoriesRoute
        laboratories={productLaboratories}
        visible={laboratoriesModalVisibility}
        onClose={onCloseLaboratoriesMenu}
      />
    </Flex>
  )
}

export default CompareProductsRoute;
