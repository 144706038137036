import { nanoid } from "nanoid";
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

export const lifesManagementFiltersModel = ({
  onClose,
  nextBaseUrl,
}) => {
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { age } = useParams();
  const [dependents, setDependents] = useState(() => {
    const currentDependents = queryParams.get("dependentsAges")
    if (!currentDependents) {
      return [{
        id: nanoid(),
        age: 0,
      }]
    }

    return currentDependents.split(",").map(dependentAge => ({
      id: nanoid(),
      age: Number(dependentAge),
    }))
  })
  const onClickFilterSubmit = () => {
    queryParams.delete("dependentsAges")

    if (dependents.length) {
      const dependentAges = dependents
        .map(dependent => dependent.age)
        .join(",");
      queryParams.append("dependentsAges", dependentAges);
    }

  const url = `/${age}/${nextBaseUrl}?${queryParams.toString()}`;
    navigate(url)
    onClose();
  }

  const onAddDependent = (dependentAge) => {
    const dependent = {
      id: nanoid(),
      age: dependentAge,
    }
    setDependents([...dependents, dependent])
  }

  const onRemoveDependent = (dependentId) => {
    setDependents(dependents.filter((dependent) => dependent.id !== dependentId));
  }

  const onEditDependent = (dependentId, value) => {
    setDependents(dependents.map(dependent => {
      if (dependent.id === dependentId) {
        return {
          id: dependentId,
          age: value,
        }
      }
      return dependent;
    }));
  }

  return {
    onClickFilterSubmit,
    dependents,
    onAddDependent,
    onRemoveDependent,
    onEditDependent,
  }
}