
import Flex from "../../components/atoms/Flex/Flex";
import { productsModel } from "./viewModel/productsModel";
import 'react-loading-skeleton/dist/skeleton.css';
import Loading from "../../components/molecules/Loading/Loading"
import ButtonFixed from "../../components/molecules/ButtonFixed";
import IntroSection from "./components/IntroSection"
import CardProduct from "./components/CardProduct";
import { isMobile } from "react-device-detect";
import FiltersResumeDesktop from "../Providers/components/FiltersResumeDesktop";
import FiltersResumeMobile from "../Providers/components/FiltersResumeMobile";
import ButtonsFixedDesktop from "../../components/molecules/ButtonsFixedDesktop";
import AllFilters from "../../components/organisms/AllFilters/AllFilters";
import LifesManagementFilters from "../../components/organisms/LifesManagementFilter/LifesManagementFilters";
import VoucherFilters from "../../components/organisms/VoucherFilter/VoucherFilters";
import Typography from "../../components/atoms/Typography/Typography";
const ProductsRoute = () => {
  const {
    onSelectProduct,
    isCheckedProduct,
    providersProducts,
    redirectToCompare,
    forwardTextButton,
    isDisabledToCompareButton,
    handleViewInformation,
    checkedViewInformation,
    isLoading,
    onClickAllFiltersOpen,
    onClickLifeManagementFilterOpen,
    onClickVoucherFilterOpen,
    stateSelected,
    lifeQuantity,
    copayment,
    accommodation,
    openAllFilters,
    openLifeManagementFilter,
    openVoucherFilter,
    
    onClickAllFiltersClose,
    availableHospitals,
    onChangeStateMutation,
    onChangeHospitals,
    onClearHospitals,
    accreditNetworksIsLoading,
    onClickLifeManagementFilterClose,
    onClickVoucherFilterClose,
  } = productsModel();

  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        gap: "10px",
        marginTop: "40px",
      }}
    >
      {isMobile &&
        <FiltersResumeMobile
          stateSelected={stateSelected}
          lifeQuantity={lifeQuantity}
          copayment={copayment}
          accommodation={accommodation}
          onClickAllFiltersOpen={onClickAllFiltersOpen}
          onClickVoucherFilterOpen={onClickVoucherFilterOpen}
          title={"Escolha os planos"}
          subtitle={"Compare as opções que mais combinam com as suas necessidades."}
        />
      }
      {!isMobile && <IntroSection />}
      {isLoading && <Loading/>}
      {!providersProducts.length && (
        <Typography
          variant="subheading1"
          fontWeight={700}
          color="terceary"
        >
          Não foi encontrado nenhum plano de saúde, tente usar outro filtro.
        </Typography>
      )}
      <Flex
        style={{
          ...(isMobile && {
            width: "100%",
          }),
        }}
      >
        {!isMobile &&
          <FiltersResumeDesktop
            stateSelected={stateSelected}
            lifeQuantity={lifeQuantity}
            copayment={copayment}
            accommodation={accommodation}
            onClickLifeManagementFilterOpen={onClickLifeManagementFilterOpen}
            onClickVoucherFilterOpen={onClickVoucherFilterOpen}
            onClickAllFiltersOpen={onClickAllFiltersOpen}
          />
        }
        <Flex
          flexDirection="column"
          gapSize={"lg"}
          style={{
            marginBottom: "50px",
            paddingBottom: "160px", 
            width: "100%",
            ...(!isMobile && {
              width: "1200px",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }),
          }}
        >
          {providersProducts.map(product => (
            <CardProduct
              providerName={product.provider.name}
              productId={product.id}
              key={product.id}
              name={product.name}
              accommodation={product.accommodation}
              copayment={product.copayment}
              adherence={product.adherence}
              price={product.price}
              reimbursement={product.reimbursement}
              onClickProduct={onSelectProduct}
              isSelectedProduct={isCheckedProduct(product.id)}
              onClickViewInformation={() => handleViewInformation(product.id)}
              viewInformation={checkedViewInformation(product.id)}
              priceByAges={product.pricesByAge}
              cashback={product.cashback}
              cashbackPrice={product.cashbackPrice}
              cashbackDuration={product.cashbackDuration}
            />
          ))}
        </Flex>
      </Flex>
      <AllFilters
        onClose={onClickAllFiltersClose}
        visible={openAllFilters}
        hospitals={availableHospitals}
        onChangeStateMutation={onChangeStateMutation}
        onChangeHospitals={onChangeHospitals}
        onClearHospitals={onClearHospitals}
        isLoading={isLoading && accreditNetworksIsLoading}
        nextBaseUrl={"providers/products"}
      />
      <LifesManagementFilters
        onClose={onClickLifeManagementFilterClose}
        visible={openLifeManagementFilter}
        nextBaseUrl={"providers/products"}
      />
      <VoucherFilters
        onClose={onClickVoucherFilterClose}
        visible={openVoucherFilter}
        nextBaseUrl={"providers/products"}
      />
      {isMobile ?
        <ButtonsFixedDesktop
          onClickAllFiltersOpen={onClickAllFiltersOpen}
          onClickLifeManagementFilterOpen={onClickLifeManagementFilterOpen}
          onClickContinueButton={redirectToCompare}
          disabled={isDisabledToCompareButton}
          text={"Comparar planos"}
        /> :
        (!openAllFilters && !openLifeManagementFilter && !openVoucherFilter) && (
          <ButtonFixed
            onClick={redirectToCompare}
            disabled={isDisabledToCompareButton}
            text={forwardTextButton()}
          />
        )
      }
    </Flex>
  )
}

export default ProductsRoute;