import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const TagDescription = ({
  icon,
  description,
  infoIcon,
  onClick,
}) => {
  return (
    <Flex
      onClick={onClick}
      style={{
        backgroundColor: "#F4F1FC",
        borderRadius: 4,
        padding: 4,
        gap: 2,
      }}
    >
      {icon}
      <Typography
        variant={"subheading2"}
        fontWeight={500}
        color={"info"}
      >
        {description}
      </Typography>
      {Boolean(infoIcon) && {infoIcon}}
    </Flex>
  )
}

export default TagDescription;