import Flex from "../../components/atoms/Flex/Flex";
import Typography from "../../components/atoms/Typography/Typography";
import { hospitalsModel } from "./viewModel/hospitalsModel";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import Divider from "../../components/atoms/Divider/Divider"
import { nanoid } from "nanoid";
import Drawer from "../../components/molecules/Drawer";
import { ReactComponent as DrawerRectanbleIcon } from '../../assets/drawer-rectangle.svg';


const HospitalsRoute = ({
  productGroupId,
  visible,
  onClose,
}) => {
  const {
    hospitalsByZone,
    shouldDisplayZone,
    onClickHospitalZone,
    setHospitalInputValue,
    hospitalInputValue,
  } = hospitalsModel({ productGroupId });

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      size={"lg"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        style={{
          padding: "20px",
          width: "100%",
        }}
      >
        <DrawerRectanbleIcon style={{ marginTop: 36 }}/>
        <Typography
          variant={"h5"}
          fontWeight={400}
          color={"terceary"}
          style={{
            marginTop: 32,
            marginBottom: 16,
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          Hospitais
        </Typography>
        <input
          placeholder={"Buscar por hospital"}
          type={"text"}
          defaultValue={hospitalInputValue}
          onChange={(e) => setHospitalInputValue(e.target.value)}
          style={{
            marginTop: 12,
            marginBottom: 24,
            width: "85%",
            padding: 12,
            borderRadius: 4,
            border: "1px solid rgba(213, 217, 242, 1)",
          }}
        />
        <Flex
          flexDirection={"column"}
          gapSize={"md"}
          style={{
            overflowY: "auto",
            maxHeight: "70vh",
            width: "90%",
          }}
        >
          {Object.entries(hospitalsByZone). map(([hospitalZone, hospitals]) => (
            <Flex
              key={nanoid()}
              flexDirection={"column"}
              justifyContent={"center"} 
              alignItems={"center"}
              style={{ width: "100%" }}
            >
              <Flex justifyContent={"center"}
                style={{
                  justifyContent: "space-between",
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => onClickHospitalZone(hospitalZone)}
              >
                <Typography
                  variant="subheading1"
                  fontWeight={600}
                  color={"terceary"}
                  style={{ marginBottom: 16 }}
                >
                  {hospitalZone} - {hospitals[0].state}
                </Typography>
                {shouldDisplayZone[hospitalZone] ?
                  <FaChevronDown color="rgba(89, 0, 204, 1)"/> :
                  <FaChevronRight color="rgba(89, 0, 204, 1)"/>
                }
              </Flex>
              {shouldDisplayZone[hospitalZone] && (
                <Flex
                  flexDirection={"column"}
                  gapSize
                  style={{ width: "100%", marginBottom: 12 }}
                >
                  {hospitals.map((hospital, i) => (
                    <Flex
                      flexDirection={"column"}
                      key={hospital+i}
                      gapSize
                      style={{
                        minHeight: "48px",
                        padding: "10px",
                        width: "90%",
                        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                        borderRadius: 8,
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        variant="subheading2"
                        fontWeight={600}
                        color={"primary"}
                      >
                        {hospital.name}
                      </Typography>
                      {hospital?.services?.map(service => (
                        <Typography
                          key={service}
                          variant="subheading3"
                          fontWeight={500}
                          color={"secondary"}
                        >
                          {service}
                        </Typography>
                      ))}
                    </Flex>
                  ))}
                </Flex>
              )}
              <Divider/>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default HospitalsRoute;