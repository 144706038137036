import { motion, AnimatePresence } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

const DRAWER_SIZE = {
  "sm": "55vh",
  "md": "70vh",
  "lg": "90vh",
}

export default function Drawer({
  visible,
  onClose,
  children,
  size = DRAWER_SIZE.md,
}) {

  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, onClose]);

  return (
    <div ref={ref}>
      <AnimatePresence>
        {visible && (
            <motion.div
              drag="y"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                position: "fixed",
                bottom: 0,
                left: 0,
                zIndex: 99999,
                backgroundColor: "white",
                borderRadius: "20px 20px 0px 0px",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 12px 0px",
                height: isMobile ?DRAWER_SIZE[size] : "100vh",
                width: isMobile ? "100%" : "25%",
                paddingBottom: 12,
                touchAction: "auto",
              }}
              dragConstraints={{ bottom: 0, top: 0 }}
              dragDirectionLock
              dragElastic={0.2}
              onDragEnd={(e, info) => {
                if (e.type === 'pointercancel') return;
                if (info.offset.y > 150) onClose();
              }}
              exit={{ opacity: 0.5, y: "110%" }}
              initial={{ opacity: 0.5, y: "110%" }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ damping: 10, type: "just" }}
            >
              {children}
            </motion.div>
        )}
      </AnimatePresence>
    </div>

  );
}

