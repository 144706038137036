import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";

async function createLeadMutation({
  email,
  cellphone,
}) {
  const response = await axios.post(
    `${API}/leads`,
    {
      email,
      cellphone,
    },
  );
  return response.data;
}

export const leadMutation = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => createLeadMutation(e),
  );

  return {
    isSuccessLeadUseCase: isSuccess,
    dataLeadUseCase: data,
    isLoadingLeadUseCase: isLoading,
    errorLeadUseCase: error,
    mutateCreateLead: mutate,
  }
}
