import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Flex from '../../../atoms/Flex/Flex';
import Typography from '../../../atoms/Typography/Typography';

const PriceFilter = ({
  currentPrice,
  onChangePrice,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      style={{
        width: "100%",
      }}
    >
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        style={{
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "27px",
          letterSpacing: "0em",
          textAlign: "left",
          margin: "6px",
          marginBottom: "0px",
        }}
      >
        <Flex
          flexDirection={"row"}
        >
          <Typography
            variant={"subheading1"}
            fontWeight={700}
            color={"terceary"}
          >
            Preço
          </Typography>
          <Typography
            variant={"subheading1"}
            fontWeight={700}
            color={"terceary"}
          >
            &nbsp;R$ {currentPrice}
          </Typography>
        </Flex>
      </Flex>
      <Flex
        gapSize={"md"}
        style={{
          marginTop: 8,
          width: "90%",
          alignSelf: "center",
        }}
      >
        <Slider
          defaultValue={currentPrice}
          min={0}
          max={5000}
          step={50}
          onChange={onChangePrice}
          handleStyle={{
            borderColor: '#342C44',
            height: 25,
            width: 25,
            marginLeft: 0,
            marginTop: -12,
            backgroundColor: '#5900CC',
          }}
          marks={
            {
              0: "R$ 0",
              5000: "R$ 5000",
            }
          }
        />
      </Flex>
    </Flex>
  )
}

export default PriceFilter;