import { useEffect } from "react";
import { useState } from "react";

export const laboratoriesModel = ({
  laboratories = [],
}) => {
  const [shouldDisplayUnits, setShouldDisplayUnits] = useState({})
  const [laboratoryInputValue, setLaboratoryInputValue] = useState("")
  const [builtLaboratories, setBuiltLaboratories] = useState([])

  const buildLabs = (laboratories) => {
    const filteredData = laboratoryInputValue ?
      laboratories.filter(item => item.name.toLocaleLowerCase().includes(laboratoryInputValue.toLocaleLowerCase()))
      : laboratories

    const labs = filteredData
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((acc, laboratory) => {
        if (laboratory?.name?.includes(" Un.")) {
          const [laboratoryName, laboratoryUnit] = laboratory.name.split(" Un.");
          const treatedLabUnit = laboratoryUnit.replaceAll("-", "").trim();
          acc[laboratoryName] = [
            ...(acc[laboratoryName] ? acc[laboratoryName] : []),
            treatedLabUnit,
          ];
        } else {
          acc[laboratory.name] = [];
        }
        return acc;
      }, {});
      setBuiltLaboratories(labs)
  }

  const setDisplayUnits = (laboratoryName) => {
    setShouldDisplayUnits({
      ...shouldDisplayUnits,
      [laboratoryName]: shouldDisplayUnits[laboratoryName] ? false: true,
    });
  }

  useEffect(() => {
    buildLabs(laboratories)
  }, [laboratories, laboratoryInputValue])

  return {
    setDisplayUnits,
    shouldDisplayUnits,
    labs: builtLaboratories,
    laboratoryInputValue,
    setLaboratoryInputValue,
  }
}