import React, { useState } from 'react';
import Select from 'react-select';

function SearchableSelect({
  data,
  onSelectHospital,
  onClearHospitals,
  isLoading,
}) {
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const filterOptions = (inputValue) => {
    return data
      .filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 5)
      .map(item => ({ value: item, label: item }));
  };

  const options = filterOptions(inputValue);

  const handleInputChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newValue);
      return newValue;
    }
  };

  const handleChange = (selected, actionMeta) => {
    if (actionMeta.action === 'clear') {
      setSelectedOptions([]);
      onSelectHospital([]);
      onClearHospitals();
    } else if (actionMeta.action === 'remove-value') {
      if (!selected.length) {
        onClearHospitals();
      }
    }
    setSelectedOptions(selected);
    onSelectHospital(selected ? selected.map(option => option.value) : []);
  };

  return (
    <Select
      options={options}
      onInputChange={handleInputChange}
      onChange={handleChange}
      placeholder="Selecione ou digite..."
      noOptionsMessage={() => 'Nenhum hospital encontrado'}
      isMulti={true}
      isSearchable={true}
      isClearable={true}
      value={selectedOptions}
      isDisabled={isLoading}
    />
  );
}

export default SearchableSelect;
