import Flex from "../../../components/atoms/Flex/Flex";
import { isMobile } from 'react-device-detect';
import Typography from "../../../components/atoms/Typography/Typography";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import Divider from "../../../components/atoms/Divider/Divider";
import ducaPositiveLogo from '../../../assets/duca_positive.png';
import { convertPriceToMoney } from "../../../utils/masks";

const ProviderCard = ({
  product,
  lifeNumber,
  onClick,
  checkedProvider,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      style={{
        width: "95%",
        borderRadius: "10px",
        border: "1px solid #F4F1FC",
        ...(!isMobile && {
          width: "750px",
        }),
        cursor: "pointer",
        padding: 8,
      }}
      onClick={onClick}
      
    >
      <Flex
        justifyContent={"space-around"}
        style={{
          width: "100%",
          alignSelf: "center",
          alignItems: "center",
          ...(Boolean(product.cashback) && { marginBottom: 8 }),
        }}
      >
        <img
          src={`https://duca-assets.s3.amazonaws.com/providers/${product.provider.name}.png`}
          style={{ maxWidth: 90, maxHeight: 32 }}
          alt={`${product.provider.name} logo`}
        />
        <Flex flexDirection={"column"}>
          <div
            style={{
              fontWeight: 700,
              fontSize: 12,
              color: "rgba(139, 145, 179, 1)",
            }}
          >
            à partir de:
          </div>
          <Flex flexDirection={"row"}>
            <div style={{
              fontWeight: 500,
              fontSize: 24,
            }}>
              R$ {product.price}
            </div>
            <Flex
              style={{
                alignSelf: "end",
              }}
            >/mês</Flex>
          </Flex>
          <Typography
            variant="subheading3"
            fontWeight={400}
            style={{color: "rgba(139, 145, 179, 1)"}}
          >
            {lifeNumber}
          </Typography>
        </Flex>
          { checkedProvider ?
            (<FaCheckSquare color={"rgba(89, 0, 204, 1)"}/>) :
            (<FaRegSquare color={"rgba(213, 217, 242, 1)"}/>)
          }
      </Flex>
      {Boolean(product.cashback) && <Divider />}
      {Boolean(product.cashback) && (
        <Flex
          style={{
            marginTop: 8,
            backgroundColor: "#F4F1FC",
            borderRadius: 4,
            alignItems: "center",
          }}
        >
          <Flex>
            <img
              src={ducaPositiveLogo}
              style={{ maxWidth: 90, maxHeight: 52 }}
              alt="Duca logo"
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            style={{ gap: 2 }}
          >
            <Typography
              variant="subheading3"
              fontWeight={700}
              color={"secondary"}
            >
              te devolvemos&nbsp;
              <span style={{ color: "#5900CC", fontSize: 12 }}>
                {convertPriceToMoney(product.cashbackPrice)}
              </span>
              &nbsp;durante&nbsp;
              <span style={{ color: "#5900CC", fontSize: 12 }}>
                {product.cashbackDuration}
              </span>&nbsp;meses
            </Typography>
            <Typography
              variant="subheading3"
              fontWeight={700}
              color={"secondary"}
            >
              {product.cashback}% de cashback&nbsp;
              <span style={{ color: "#5900CC", fontSize: 12 }}>
                Duca Saúde
              </span>
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default ProviderCard;