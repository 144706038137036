import { IoFilterOutline } from "react-icons/io5"
import Flex from "../../../components/atoms/Flex/Flex"
import Typography from "../../../components/atoms/Typography/Typography"
import FilterButton from "./FilterButton"
import FiltersSelectedSectionDesktop from "./FiltersSelectedSectionDesktop"
import { MdOutlineGroupAdd } from "react-icons/md"
import CashbackSection from "./CashbackSection"

const FiltersResumeDesktop = ({
  onClickLifeManagementFilterOpen,
  onClickVoucherFilterOpen,
  onClickAllFiltersOpen,
  stateSelected,
  lifeQuantity,
  copayment,
  accommodation,
}) => {
  return (
    <Flex flexDirection={"column"}>
      <Flex
        flexDirection={"column"}
        gapSize={"md"}
        style={{
          width: 300,
          borderRadius: 8,
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
          padding: 16,
          marginTop: 90,
        }}
      >
        <Typography
          variant="subheading2"
          fontWeight={600}
          color={"info"}
        >
          Resumo do seu plano
        </Typography>
        <FiltersSelectedSectionDesktop
          state={stateSelected}
          lifesQuantity={lifeQuantity}
          copayment={copayment}
          accommodation={accommodation}
          onClick={onClickAllFiltersOpen}
        />
        <Flex flexDirection={"column"} gapSize>
          <FilterButton
            onClick={onClickAllFiltersOpen}
            icon={<IoFilterOutline size={16} color={"#5900CC"}/>}
            text={"Filtrar Planos"}
          />
          <FilterButton
            onClick={onClickLifeManagementFilterOpen}
            icon={<MdOutlineGroupAdd size={16} color={"#5900CC"}/>}
            text={"Adicionar Vidas"}
          />
        </Flex>
      </Flex>
      <CashbackSection onClick={onClickVoucherFilterOpen}/>
    </Flex>
  )
}

export default FiltersResumeDesktop