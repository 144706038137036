import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const IntroSection = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gapSize
    >
      <Typography
        variant="h5"
        fontWeight={400}
        color="info"
        style={{ marginTop: 24, textAlign: "center" }}
      >
        Compare os planos
      </Typography>
      <Typography
        variant="subheading1"
        fontWeight={400}
        color={"secondary"}
      >
        Escolha até quatro opções entre os planos que mais combinam com as suas necessidades
      </Typography>
    </Flex>
  );
};

export default IntroSection;
