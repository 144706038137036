import { CiLocationOn } from "react-icons/ci";
import {
  MdOutlineSupervisorAccount,
  MdOutlineMonetizationOn,
  MdSingleBed,
} from "react-icons/md";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import { getAccommodation, getCopayment } from "../../../utils/i18n-product";

const FiltersSelectedSectionDesktop = ({
  state,
  lifesQuantity,
  copayment = [],
  accommodation = [],
  onClick,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      gapSize={"md"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <Flex gapSize={"xsm"}>
        <CiLocationOn size={16} color={"#5900CC"}/>
        <Typography
          variant="subheading2"
          fontWeight={700}
          color={"primary"}
        >
          {state}
        </Typography>
      </Flex>
      <Flex gapSize={"md"}>
        <Flex gapSize={"xsm"}>
          <MdOutlineSupervisorAccount size={16} color={"#5900CC"}/>
          <Typography
            variant="subheading2"
            fontWeight={500}
            color={"primary"}
          >
            {lifesQuantity} {lifesQuantity > 1 ? "vidas": "vida"}
          </Typography>
        </Flex>
      </Flex>
      <Flex gapSize={"md"}>
        <Flex gapSize={"xsm"}>
          <MdOutlineMonetizationOn size={16} color={"#5900CC"}/>
          <Flex flexDirection={"column"}>
            {copayment.map(value => (
              <Typography
                key={value}
                variant="subheading2"
                fontWeight={500}
                color={"primary"}
              >
                {getCopayment(value)}
              </Typography>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex gapSize={"md"}>
        <Flex gapSize={"xsm"}>
          <MdSingleBed size={16} color={"#5900CC"}/>
          <Flex flexDirection={"column"}>
            {accommodation.map(value => (
              <Typography
                key={value}
                variant="subheading2"
                fontWeight={500}
                color={"primary"}
              >
                {getAccommodation(value)}
              </Typography>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FiltersSelectedSectionDesktop