import { CiLocationOn } from "react-icons/ci";
import {
  MdOutlineSupervisorAccount,
  MdOutlineMonetizationOn,
  MdSingleBed,
} from "react-icons/md";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";

const COPAYMENT_MAP_MOBILE = {
  with: "com",
  without: "sem",
  with_partial: "parcial",
}
const ACCOMMODATION_MAP_MOBILE = {
  shared: "compartilhado",
  private: "privado",
}

const FiltersSelectedSectionMobile = ({
  state,
  lifesQuantity,
  copayment,
  accommodation,
  onClick,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      gapSize={"xsm"}
      style={{
        cursor: "pointer",
        width: "100%",
        backgroundColor: "#4A25AA",
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 25,
      }}
      onClick={onClick}
    >
      <Flex justifyContent={"space-evenly"}>
        <Flex gapSize={"xsm"}>
          <CiLocationOn size={16} color={"#F8F4FF"}/>
          <Typography
            variant="subheading2"
            fontWeight={700}
            color={"neutral"}
          >
            {state}
          </Typography>
        </Flex>
        <Flex gapSize={"md"}>
          <Flex gapSize={"xsm"}>
            <MdSingleBed size={16} color={"#F8F4FF"}/>
            <Typography
              variant="subheading2"
              fontWeight={500}
              color={"neutral"}
            >
              {accommodation.map(v => ACCOMMODATION_MAP_MOBILE[v]).join(", ")}
            </Typography>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent={"space-evenly"}>
      <Flex gapSize={"md"}>
          <Flex gapSize={"xsm"}>
            <MdOutlineSupervisorAccount size={16} color={"#F8F4FF"}/>
            <Typography
              variant="subheading2"
              fontWeight={500}
              color={"neutral"}
            >
              {lifesQuantity}
            </Typography>
          </Flex>
        </Flex>
        <Flex gapSize={"md"}>
          <Flex gapSize={"xsm"}>
            <MdOutlineMonetizationOn size={16} color={"#F8F4FF"}/>
            <Typography
              variant="subheading2"
              fontWeight={500}
              color={"neutral"}
            >
              Coparticipação: {copayment.map(v => COPAYMENT_MAP_MOBILE[v]).join(", ")}
            </Typography>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FiltersSelectedSectionMobile