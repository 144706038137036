import Divider from "../../../components/atoms/Divider/Divider";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import { FaAngleRight } from "react-icons/fa";

const LaboratorySection = ({ laboratories, onClickLaboratoryMenu }) => {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gapSize="10px"
        onClick={onClickLaboratoryMenu}
        style={{ cursor: "pointer" }}
      >
        <Flex
          justifyContent="space-between"
        >
          <Typography
            variant="subheading2"
            fontWeight={500}
            color="terceary"
          >
            Laboratórios ({laboratories?.length})
          </Typography>
          <FaAngleRight
            style={{
              marginRight: "5px",
              color: "midnightblue",
            }}
          />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="baseline"
          alignItems="baseline"
          style={{ textAlign: "left" }}
        >
          {laboratories?.slice(0, 2).map(laboratory => (
            <Typography
              key={laboratory.name}
              variant="subheading1"
              fontWeight={600}
              color="terceary"
            >
              {laboratory.name}
            </Typography>
          ))}
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};

export default LaboratorySection;
