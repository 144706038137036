import { resumeModel } from "./viewModel/resumeModel";
import Typography from "../../components/atoms/Typography/Typography";
import Flex from "../../components/atoms/Flex/Flex";
import Loading from "../../components/molecules/Loading/Loading";
import { isMobile } from "react-device-detect";
import SummarySection from "./components/SummarySection";
import InsuranceProviderSection from "./components/InsuranceProviderSection";
import LifeQuantitySection from "./components/LifeQuantitySection";
import AccommodationTypeSection from "./components/AccommodationTypeSection";
import CopaymentTypeSection from "./components/CopaymentTypeSection";
import ReimbursementSection from "./components/ReimbursementSection"
import HospitalSection from "./components/HospitalSection";
import LaboratorySection from "./components/LaboratorySection";
import ContractualInfoSection from "./components/ContractualInfoSection";
import VoucherSection from "./components/VoucherSection";
import HospitalsRoute from "../Hospitals/HospitalsRoute";
import LaboratoriesRoute from "../Laboratories/LaboratoriesRoute";
import ButtonFixedDouble from "../../components/molecules/ButtonFixedDouble";

const ResumeRoute = () => {
  const {
    isLoading,
    product,
    redirectToLogin,
    onClickTalkToUs,
    buildDependentsText,
    buildHolderText,
    dependentsAges,
    onClickHospitalMenu,
    onClickLaboratoryMenu,
    hospitalsModalVisibility,
    onCloseHospitalMenu,
    onCloseLaboratoriesMenu,
    laboratoriesModalVisibility,
    laboratories,
  } = resumeModel();

  if (isLoading || !product) {
    return (
      <Loading/>
    )
  }
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      style={{
        padding: "24px 24px 80px 24px",
        gap: "10px",
        marginTop: "40px",
        ...(!isMobile && {
          width: "40%",
          alignSelf: "center",
        }),
      }}
    >
      <Typography
        variant={"h5"}
        fontWeight={400}
        color={"terceary"}
        style={{
          marginBottom: 16,
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        Resumo da simulação
      </Typography>
      <SummarySection product={product}/>
      {Boolean(product?.cashback) && (
        <VoucherSection
          cashback={product?.cashback}
          cashbackPrice={product?.cashbackPrice}
          cashbackDuration={product?.cashbackDuration}
        />
      )}
      <InsuranceProviderSection name={product.provider.name}/>
      <LifeQuantitySection
        buildHolderText={buildHolderText}
        dependentsAges={dependentsAges}
        buildDependentsText={buildDependentsText}
      />
      <AccommodationTypeSection accommodation={product.accommodation} />
      <CopaymentTypeSection copayment={product.copayment} />
      <ReimbursementSection reimbursement={product.reimbursement} />
      <HospitalSection
        hospitals={product.hospitals}
        onClickHospitalMenu={onClickHospitalMenu}
      />
      <LaboratorySection
        laboratories={product.laboratories}
        onClickLaboratoryMenu={onClickLaboratoryMenu}
      />
      <ContractualInfoSection />
      <ButtonFixedDouble
        onClickLeft={onClickTalkToUs}
        onClickRight={redirectToLogin}
      />
      <HospitalsRoute
        productGroupId={product.productGroupId}
        visible={hospitalsModalVisibility}
        onClose={onCloseHospitalMenu}
      />
      <LaboratoriesRoute
        laboratories={laboratories}
        visible={laboratoriesModalVisibility}
        onClose={onCloseLaboratoriesMenu}
      />
    </Flex>
  );
};

export default ResumeRoute;