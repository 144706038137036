import Typography from '../../atoms/Typography/Typography';
import Button from '../../molecules/Button';
import { voucherFiltersModel } from './viewModel/voucherFiltersModel';
import Flex from '../../atoms/Flex/Flex';
import Input from '../../molecules/Input';
import Drawer from '../../molecules/Drawer';
import { ReactComponent as DrawerRectanbleIcon } from '../../../assets/drawer-rectangle.svg';

const VoucherFilters = ({
  onClose,
  visible,
  nextBaseUrl,
}) => {
  const {
    onChangeInput,
    voucherInput,
    onClickVerifyVoucher,
    errorMessage,
    isLoading,
    disabled,
    onSuccessVoucher,
  } = voucherFiltersModel({
    onClose,
    nextBaseUrl,
  })

  return (
    <Drawer
      visible={visible}
      onClose={onSuccessVoucher}
      size={"sm"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
      >
        <DrawerRectanbleIcon style={{ marginTop: 36 }}/>
        <Typography
          variant={"h6"}
          fontWeight={400}
          color={"info"}
          style={{ alignSelf: "center", marginTop: 30 }}
        >
          Cashback
        </Typography>
      </Flex>
      <Flex
       flexDirection={"column"}
       justifyContent={"space-between"}
       gapSize={"lg"}
       style={{
         height: "100%",
         padding: "1rem",
       }}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          gapSize={"lg"}
          style={{ padding: 8 }}
        >
          <Typography
            variant="subheading1"
            fontWeight={400}
            color={"terceary"}
          >
            Recebeu um código promocional de algum dos parceiros da Duca? <br/>Insira abaixo:
          </Typography>
          <Input
            placeholder={"Código promocional"}
            type={"text"}
            defaultValue={voucherInput}
            onChange={onChangeInput}
          />
        </Flex>
        <Flex style={{ alignSelf: "center" }}>
          <Typography
            variant="subheading2"
            fontWeight={400}
            color={"error"}
          >
            {errorMessage}
          </Typography>
        </Flex>
      </Flex>
      <Button
        text={"Ativar código"}
        onClick={onClickVerifyVoucher}
        width={"270px"}
        height={"20px"}
        disabled={disabled || isLoading}
      />
    </Drawer>
  )
}

export default VoucherFilters;