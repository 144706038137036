const Input = ({
  autoComplete = true,
  placeholder,
  type,
  onChange,
  defaultValue,
  hasError,
  style,
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <input
        autoComplete={autoComplete ? "on" : "new-password"}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={defaultValue ? defaultValue : ''}
        style={{
          width: "275px",
          height: "30px",
          ...(hasError ? {
            border: "2px solid #f6b9b8",
          }: {
            border: "2px solid #F4F1FC",
          }),
          backgroundColor: "#F4F1FC",
          borderRadius: "5px",
          padding: defaultValue ? "20px 20px 0px" : "10px 20px 10px",
          fontWeight: "600",
          fontSize: "16px",
          color: "#181619",
          ...style,
        }}
      />
      {Boolean(defaultValue) && (
        <label
          className={defaultValue && 'filled'}
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            transform: 'translate(0, 23px) scale(1)',
            transformOrigin: 'top left',
            transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            color: '#6f81a5',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '1',
            left: '22px',
            ...(defaultValue && {
                transform: 'translate(0, 12px) scale(0.8)',
                color: '#7D7D7D',
              }),
          }}>
          {placeholder}
        </label>
      )}
    </div>
  )
}

export default Input