import { groupBy, maxBy } from "lodash"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { productsQueryUseCase } from "../../Products/useCases/products/mutations/productsMutation";
import { trackAction, trackPage } from "../../../utils/segment";
import { vouchersQuery } from "../../../components/organisms/VoucherFilter/useCases/queries/getVouchersQuery";
import { setFromLS } from "../../../utils/localStorage";
import {
  accreditNetworksQueryOnDemand,
} from "../../Products/useCases/accreditNetworks/queries/accreditNetworksQueryOnDemand";
import { getProviderName } from "../../../utils/i18n-product";
import { isBelowMaxAge } from "../../../utils/parser";

export const providersModel = () => {
  const [providerIds, setProvidersIds] = useState([])
  const [builtProducts, setBuiltProducts] = useState([])
  const [openAllFilters, setOpenAllFilters] = useState(false);
  const [openLifeManagementFilter, setOpenLifeManagementFilter] = useState(false);
  const [openMinimumLifeCheck, setOpenMinimumLifeCheck] = useState(false);
  const [openVoucherFilter, setOpenVoucherFilter] = useState(false);
  const [availableHospitals, setAvailableHospitals] = useState([])
  const [warningMessage, setWarningMessage] = useState(false);
  const { age } = useParams();
  const navigate = useNavigate();
  trackPage("plg-providers")

  const queryParams = new URLSearchParams(location.search);
  const state = queryParams.get('state');
  const dependentsAges = queryParams.get('dependentsAges');
  const maxPrice = queryParams.get('maxPrice');
  const voucher = queryParams.get('voucher');
  const copayment = queryParams.get("copayment");
  const accommodation = queryParams.get("accommodation");
  const [currentState, setCurrentState] = useState(state)

  const {
    dataVouchersUseCase,
    isSuccessVouchersUseCase,
  } = vouchersQuery({ code: voucher });

  const {
    dataProductQueryUseCase,
    isLoadingProductQueryUseCase,
    isSuccessProductQueryUseCase,
  } = productsQueryUseCase({
    age,
    dependents: dependentsAges ? dependentsAges.split(",") : [],
    state: currentState,
    providerIds: [],
    maxPrice,
    copayment,
    accommodation,
  });

  const {
    accreditNetworksData,
    accreditNetworksIsSuccess,
    refetchAccreditNetwork,
    accreditNetworksIsLoading,
  } = accreditNetworksQueryOnDemand(true)

  const onChangeStateMutation = (state) => {
    refetchAccreditNetwork({ state })
    setCurrentState(state)
  }

  const onChangeHospitals = (hospitalsSelected) => {
    if (!hospitalsSelected.length) return

    const accreditsWithHospitalsChosen = accreditNetworksData.filter(accredit => {
      const hasHospital = accredit.hospitals.find(hospital =>
        hospitalsSelected.includes(hospital.name),
      )
      return hasHospital
    }).map(accredit => accredit.productGroupId)

    const filterByHospitals = dataProductQueryUseCase.filter(product =>
      accreditsWithHospitalsChosen.includes(product.productGroupId),
    )

    setBuiltProducts(
      mapByProviderWithVoucher(
        filterByHospitals,
        dataVouchersUseCase,
      ),
    )    
  }

  const onClearHospitals = () => {
    setBuiltProducts(
      mapByProviderWithVoucher(
        dataProductQueryUseCase,
        dataVouchersUseCase,
      ),
    )  
  }
  const getLifeNumber = (minLifes) => {
    return `Mínimo de ${minLifes} vida${minLifes > 1 ? "s" : ""}`
  };

  const handleProvider = (providerId) => {
    const providerFound = providerIds.some(id => id === providerId)
    if (providerFound) {
      setProvidersIds(providerIds.filter(id => id !== providerId));
    } else {
      setProvidersIds([...providerIds, providerId]);
    }
  }

  const isCheckedProvider = (providerId) => {
    return providerIds.some(id => id === providerId)
  }

  const getMaxAgeWithin = () => {
    const dependents = dependentsAges ? dependentsAges.split(",").map(d => Number(d)) : []

    const agesArray = [Number(age), ...dependents]
    
    return Math.max(...agesArray)
  }

  const mapByProviderWithVoucher = (
    products,
    vouchers = [],
  ) => {
    const maxAgeSelected = getMaxAgeWithin()

    const productsFiltered = products.reduce((acc, product) => {
      const voucherFound = vouchers[0]?.cashback.find(voucher =>
        voucher.providerId === product.provider.id,
      )

      if (!isBelowMaxAge(product.provider.maxAgeAcceptance, maxAgeSelected)) {
        return acc;
      }

      return [
        ...acc,
        {
          ...product,
          price: (product.price / 100).toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          cashbackDuration: voucherFound ? voucherFound.duration : undefined,
          cashback: voucherFound ? voucherFound.percentage : undefined,
          cashbackPrice: voucherFound ?
            product.price * (voucherFound.percentage / 100)
            : 0,
        },
      ]
    }, [])

    return groupByProviderId(productsFiltered)
  }
  
  const groupByProviderId = (products) => {
    const providers = groupBy(products, 'provider.id');  
    return Object.entries(providers);
  }

  const onClickAllFiltersOpen = () => {
    refetchAccreditNetwork({ state })
    setOpenAllFilters(true);
  }

  const onClickAllFiltersClose = () => {
    setOpenAllFilters(false);
  }

  const onClickLifeManagementFilterOpen = () => {
    setOpenLifeManagementFilter(true);
  }

  const onClickLifeManagementFilterClose = () => {
    setOpenLifeManagementFilter(false);
  }

  const onClickVoucherFilterOpen = () => {
    setOpenVoucherFilter(true);
  }

  const onClickVoucherFilterClose = () => {
    setOpenVoucherFilter(false);
  }

  const onClickCloseMinimumLifeCheck = () => {
    setOpenMinimumLifeCheck(false);
  }

  const onClickAddLifesFromWarning = () => {
    setOpenMinimumLifeCheck(false);
    setOpenLifeManagementFilter(true);
  }

  function getUniqueProviders(data) {
    const providerMap = new Map();

    data.forEach(item => {
        const provider = item.provider;
        if (!providerMap.has(provider.id)) {
            providerMap.set(provider.id, provider);
        }
    });

    return Array.from(providerMap.values());
}

  const validateProvidersAllowed = () => {
    const lifeQuantityChosen = 1 + (dependentsAges?.length ? dependentsAges?.length - 1 : 0)

    const providers = getUniqueProviders(dataProductQueryUseCase)

    const providersChosen = providerIds.join(',');

    const providersMissingMinimumLifes = providers.filter(p => {
      return providersChosen.includes(p.id) && lifeQuantityChosen < p.minLifes
    })

    return providersMissingMinimumLifes;
  }

  const onClickContinueButton = () => {
    const providersBlocked = validateProvidersAllowed()
    if (providersBlocked.length) {
      setOpenMinimumLifeCheck(true)
      const providers = providersBlocked.map(p => getProviderName(p.name))
      const maxBlockedLife = maxBy(providersBlocked, "minLifes").minLifes - 1;
      const quantity = maxBlockedLife - (dependentsAges ? dependentsAges.split(",").length  : 0)
      const buildMessage = `
      Para seguir com ${providers.join(", ")}. Você deve adicionar mais ${quantity} vida${quantity > 1 ? "s" : ""}
      `
      setWarningMessage(buildMessage)
      return
    }
    trackAction('plg-providers-continue-button', {
      selectedProvidersQuantity: providerIds.length,
    })
    queryParams.delete("providerId")

    const state = queryParams.get("state")

    if(!state) {
      queryParams.append("state", state);
    }
  
    const providerIdsString = providerIds.join(',');
    queryParams.append("providerId", providerIdsString);
  
    const url = `/${age}/providers/products?${queryParams.toString()}`;
    navigate(url)
  }

  const getAccommodation = (accommodation) => {
    if (!accommodation) {
      return ["shared", "private"]
    }
    return accommodation.split(",")
  }

  const getCopayment = (copayment) => {
    if (!copayment) {
      return ["with", "with_partial", "without"]
    }
    return copayment.split(",")
  }

  useEffect(() => {
    const utm_source = queryParams.get('utm_source');
    if (utm_source) {
      setFromLS("utm_source", utm_source)
    }
  }, [])

  useEffect(() => {
    if (accreditNetworksIsSuccess) {
      const uniqueNames = new Set(
        accreditNetworksData.flatMap(accredit => accredit.hospitals.map(hospital => hospital.name)),
      );
      const hospitalNames = Array.from(uniqueNames)
      setAvailableHospitals(hospitalNames);
    }
  }, [accreditNetworksIsSuccess])

  useEffect(() => {
    if (isSuccessProductQueryUseCase && isSuccessVouchersUseCase) {
      const productsWithVoucher = mapByProviderWithVoucher(
        dataProductQueryUseCase,
        dataVouchersUseCase,
      )
      setBuiltProducts(productsWithVoucher)
    }
  }, [isSuccessVouchersUseCase, isSuccessProductQueryUseCase])

  return {
    isLoading: isLoadingProductQueryUseCase,
    accreditNetworksIsLoading, accreditNetworksIsLoading,
    providersProducts: builtProducts,
    getLifeNumber,
    isCheckedProvider,
    handleProvider,
    onClickContinueButton,
    providerIds,
    onClickAllFiltersOpen,
    onClickAllFiltersClose,
    onClickLifeManagementFilterOpen,
    onClickLifeManagementFilterClose,
    onClickAddLifesFromWarning,
    openLifeManagementFilter,
    openAllFilters,
    availableHospitals,
    onClickVoucherFilterClose,
    onClickVoucherFilterOpen,
    onClickCloseMinimumLifeCheck,
    openMinimumLifeCheck,
    openVoucherFilter,
    onChangeStateMutation,
    warningMessage,
    onChangeHospitals,
    onClearHospitals,
    stateSelected: currentState,
    lifeQuantity: dependentsAges ? dependentsAges.split(",").length + 1 : 1,
    copayment: getCopayment(copayment),
    accommodation: getAccommodation(accommodation),
  }
}
