export const getProviderName = (name) => {
  switch (name) {
    case 'intermedica':
      return "Intermédica";
    case 'levesaude':
      return "Leve Saúde";
    case 'assim':
      return "Assim Saúde";
    case 'goldencross':
      return "Golden Cross";
    case 'amil':
      return "Amil";
    case 'sulamerica':
      return "SulAmérica";
    case 'portoseguro':
      return "Porto Seguro";
    case 'bradesco':
      return "Bradesco";
    case 'omint':
      return "Omint";
  }
}

export const getCopayment = (copayment) => {
  switch (copayment) {
    case 'with':
      return "Com coparticipação total";
    case 'without':
      return "Sem coparticipação";
    case 'partial':
      return "Com coparticipação parcial";
    case 'with_partial':
      return "Com coparticipação parcial";
    default:
      return "N/a";
  }
}
export const getAdherence = (adherence) => {
  switch (adherence) {
    case 'compulsory':
      return "Compulsório";
    case 'loose':
      return "Livre adesão";
  }
}

export const getAccommodationMinified = (accommodation) => {
  switch (accommodation) {
    case 'shared':
      return "QC";
    case 'private':
      return "QP";
  }
}

export const getAccommodation = (accommodation) => {
  switch (accommodation) {
    case 'shared':
      return "Quarto compartilhado";
    case 'private':
      return "Quarto privado";
  }
}