import { isMobile } from "react-device-detect";
import Flex from "../atoms/Flex/Flex";
import Button from "./Button";
import { FaWhatsapp } from "react-icons/fa";

const ButtonFixedDouble = ({
  onClickLeft,
  onClickRight,
  disabled = false,
  text = "",
}) => {
  return (
    <Flex>
      <Button
        text={text || ""}
        width={isMobile ? "125px": "250px"}
        height="20px"
        onClick={onClickLeft}
        disabled={disabled}
        style={{
          position: "fixed",
          bottom: "20px",
          left: isMobile ? "25%" : "40%",
          transform: "translateX(-50%)",
          zIndex: 1000,
        }}
      >
        <FaWhatsapp size={24}/>
      </Button>
      <Button
        text={text || "Continuar"}
        width={isMobile ? "125px": "250px"}
        height="20px"
        onClick={onClickRight}
        disabled={disabled}
        style={{
          position: "fixed",
          bottom: "20px",
          left: isMobile ? "75%" : "60%",
          transform: "translateX(-50%)",
          zIndex: 1000,
        }}
      />
    </Flex>
  );
};

export default ButtonFixedDouble;
