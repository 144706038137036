import Flex from "../../components/atoms/Flex/Flex";
import { providersModel } from "./viewModel/providersModel"
import { isMobile } from 'react-device-detect';
import AllFilters from "../../components/organisms/AllFilters/AllFilters"
import ButtonFixed from "../../components/molecules/ButtonFixed";
import ProviderList from "./components/ProviderList";
import LifesManagementFilters from "../../components/organisms/LifesManagementFilter/LifesManagementFilters";
import VoucherFilters from "../../components/organisms/VoucherFilter/VoucherFilters";
import Typography from "../../components/atoms/Typography/Typography";
import FiltersResumeDesktop from "./components/FiltersResumeDesktop";
import FiltersResumeMobile from "./components/FiltersResumeMobile";
import ButtonsFixedDesktop from "../../components/molecules/ButtonsFixedDesktop";
import ProvidersLifeWarning from "./components/ProvidersLifeWarning";

const ProvidersRoute = () => {
  const {
    providersProducts,
    getLifeNumber,
    isLoading,
    isCheckedProvider,
    handleProvider,
    onClickContinueButton,
    providerIds,
    onClickAllFiltersOpen,
    onClickAllFiltersClose,
    openAllFilters,
    onClickLifeManagementFilterOpen,
    onClickLifeManagementFilterClose,
    onClickCloseMinimumLifeCheck,
    onClickAddLifesFromWarning,
    openMinimumLifeCheck,
    openLifeManagementFilter,
    availableHospitals,
    onClickVoucherFilterClose,
    onClickVoucherFilterOpen,
    openVoucherFilter,
    onChangeStateMutation,
    onChangeHospitals,
    onClearHospitals,
    warningMessage,
    accreditNetworksIsLoading,
    stateSelected,
    lifeQuantity,
    copayment,
    accommodation,
  } = providersModel();

  return (
    <Flex
      alignItems={"center"}
      flexDirection={"column"}
      style={{ marginTop: 40 }}
    >
      {!isMobile && (
        <Typography
          variant="h5"
          fontWeight={600}
          color="info"
          style={{ marginTop: 20 }}
        >
          Selecione as seguradoras para comparar os planos
        </Typography>
      )}
      <Flex
        gapSize={"lg"}
        style={{
          ...(isMobile && {
            width: "100%",
          }),
        }}
      >
        {!isMobile &&
          <FiltersResumeDesktop
            stateSelected={stateSelected}
            lifeQuantity={lifeQuantity}
            copayment={copayment}
            accommodation={accommodation}
            onClickLifeManagementFilterOpen={onClickLifeManagementFilterOpen}
            onClickVoucherFilterOpen={onClickVoucherFilterOpen}
            onClickAllFiltersOpen={onClickAllFiltersOpen}
          />
        }
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          style={{
            width: "100%",
            gap: "10px",
            marginTop: !isMobile && "40px",
            paddingBottom: "160px",
            ...(!isMobile && {
              alignItems: "center",
            }),
          }}
        >
          {isMobile && 
            <FiltersResumeMobile
              stateSelected={stateSelected}
              lifeQuantity={lifeQuantity}
              copayment={copayment}
              accommodation={accommodation}
              onClickAllFiltersOpen={onClickAllFiltersOpen}
              onClickVoucherFilterOpen={onClickVoucherFilterOpen}
              title={"Escolha a sua seguradora preferida"}
              subtitle={"Aqui você pode escolher as seguradoras da sua preferência para comparar os planos."}
            />
          }
          <AllFilters
            onClose={onClickAllFiltersClose}
            visible={openAllFilters}
            hospitals={availableHospitals}
            onChangeStateMutation={onChangeStateMutation}
            onChangeHospitals={onChangeHospitals}
            onClearHospitals={onClearHospitals}
            isLoading={isLoading && accreditNetworksIsLoading}
            nextBaseUrl={"providers"}
          />
          <LifesManagementFilters
            onClose={onClickLifeManagementFilterClose}
            visible={openLifeManagementFilter}
            nextBaseUrl={"providers"}
          />
          <ProvidersLifeWarning
            openMinimumLifeCheck={openMinimumLifeCheck}
            onClickCloseMinimumLifeCheck={onClickCloseMinimumLifeCheck}
            onClickAddLifesFromWarning={onClickAddLifesFromWarning}
            warningMessage={warningMessage}
          />
          <VoucherFilters
            onClose={onClickVoucherFilterClose}
            visible={openVoucherFilter}
            nextBaseUrl={"providers"}
          />
          <ProviderList
            isLoading={isLoading}
            providersProducts={providersProducts}
            getLifeNumber={getLifeNumber}
            handleProvider={handleProvider}
            isCheckedProvider={isCheckedProvider}
          />
          {isMobile ?
            <ButtonsFixedDesktop
              onClickAllFiltersOpen={onClickAllFiltersOpen}
              onClickLifeManagementFilterOpen={onClickLifeManagementFilterOpen}
              onClickContinueButton={onClickContinueButton}
              disabled={!providerIds.length}
              text={"Ver planos"}
            /> :
            (!openAllFilters && !openLifeManagementFilter && !openVoucherFilter) && (
              <ButtonFixed
                onClick={onClickContinueButton}
                disabled={!providerIds.length}
                text={"Continuar"}
              />
            )
          }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProvidersRoute;